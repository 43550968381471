import React, { useEffect } from 'react';
import './Map.scss';
import { MapContainer, TileLayer, useMap } from 'react-leaflet';
import PropTypes from 'prop-types';

const FlyTo = ({ position }) => {
  const map = useMap();

  useEffect(() => {
    if (position) {
      map.flyTo(position, 14, { duration: 2 });
    }
  }, [position]);
};

const Map = ({ center, zoom, children }) => {
  return (
    <div id="map">
      <MapContainer
        center={center}
        zoom={zoom || 2}
        scrollWheelZoom={false}
        className="map-container"
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {children}
      </MapContainer>
    </div>
  );
};

Map.propTypes = {
  center: PropTypes.any,
  children: PropTypes.node,
  zoom: PropTypes.number,
};

export { Map, FlyTo };
