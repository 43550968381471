import PropTypes from 'prop-types';
import React from 'react';
import { Select } from '../Select';
import './Field.scss';

const Field = ({
  value,
  onChange,
  type,
  step,
  dataTestId,
  placeholder = '',
  label,
  id,
  required = false,
  select,
  disabled,
  className,
  ...selectProps
}) => {
  return (
    <div className={className ? `form-field ${className}` : 'form-field'}>
      {type && type === 'checkbox' && (
        <div className="checkbox">
          <input
            disabled={disabled}
            className="field-input"
            onChange={(event) => onChange(event.target.checked)}
            id={id}
            data-testid={dataTestId}
            checked={value}
            value={value}
            type="checkbox"
          />
          <label htmlFor={id}>{`${label}${required ? ' *' : ''}`}</label>
        </div>
      )}
      {type !== 'checkbox' && (
        <>
          <label htmlFor={id}>{`${label}${required ? ' *' : ''}`}</label>
          {!select && (
            <input
              disabled={disabled}
              className="field-input"
              id={id}
              data-testid={dataTestId}
              value={value}
              onChange={(event) => onChange(event.target.value)}
              placeholder={placeholder}
              type={type || 'text'}
              name={id}
              step={step || 'any'}
            />
          )}
          {select && (
            <Select
              isDisabled={disabled}
              placeholder={placeholder}
              onChange={onChange}
              value={value}
              dataTestId={dataTestId}
              {...selectProps}
            />
          )}
        </>
      )}
    </div>
  );
};

Field.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  step: PropTypes.number,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  required: PropTypes.bool,
  select: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
};

export { Field };
