import React, { useContext } from 'react';
import { AppContext } from '../../contexts/AppContextProvider';
import { IdtContext } from '../../contexts/IdtContextProvider';
import { ContactList } from '../../pages/Contact';
import { useRequest } from '../../services/api';

const ContactListContainer = () => {
  const { enums } = useContext(IdtContext);
  const { user } = useContext(AppContext);
  const { data: contacts, loading } = useRequest(
    `/workspaces/${user.workspace_id}/contacts/`
  );

  return (
    <ContactList
      contacts={contacts && contacts.results}
      contactsLoading={loading}
      enums={enums}
    />
  );
};

export default ContactListContainer;
