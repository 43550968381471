import React, { useContext } from 'react';
import './Header.scss';
import logo from '../../assets/logo_small.png';
import { AppContext } from '../../contexts/AppContextProvider';
import { Link, useNavigate } from 'react-router-dom';
import { useLogout } from '../../services/auth';
import {
  formatDistanceToNow,
  formatDuration,
  intervalToDuration,
} from 'date-fns';
import locale from 'date-fns/locale/en-GB';
import { ScaleLoader } from '../../components/Spinner/Spinners';
import { useEffect } from 'react';

const DropDownMenu = () => {
  const { user } = useContext(AppContext);
  const logout = useLogout();
  return (
    <div className="user-menu">
      <div className="user-menu-dropdown">
        <i className="bi bi-person-circle"></i>
        <span>{user && user.username}</span>
        <i className="bi bi-caret-down-fill"></i>
      </div>
      <ul>
        <li onClick={logout}>
          <span>Logout</span>
        </li>
      </ul>
    </div>
  );
};

const formatDistanceLocale = {
  xSeconds: '{{count}}s',
  xMinutes: '{{count}}m',
  xHours: '{{count}}h',
  xDays: '{{count}}d',
  xWeeks: '{{count}}w',
  xMonths: '{{count}}m',
  xYears: '{{count}}y',
};

function formatDistance(token, count, options) {
  options = options || {};

  const result = formatDistanceLocale[token].replace('{{count}}', count);

  if (options.addSuffix) {
    if (options.comparison > 0) {
      return 'in ' + result;
    } else {
      return result + ' ago';
    }
  }

  return result;
}

const JobNotifications = () => {
  const { jobs, getJobs } = useContext(AppContext);

  useEffect(() => {
    const interval = setInterval(() => {
      // Checking if the browser tab is active before looping for api request.
      if (!document.hidden) {
        getJobs();
      }
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="jobs-menu">
      <Link to="/jobs">
        <div className="jobs-dropdown">
          <i className="bi bi-cpu-fill"></i>
          <i
            className={`processing-notification-icon bi-lightning-charge-fill ${
              jobs.filter((job) => {
                return job.last_signal.signal_name === 'executing';
              }).length > 0
                ? ''
                : 'hidden'
            }`}
          ></i>
        </div>
      </Link>
      <div className="hover-popup">
        <div className="hover-popup-header">
          <h2>Last jobs</h2>
        </div>
        <table>
          <tbody>
            {jobs.slice(0, 10).map((job, index) => {
              return (
                <tr key={index} className="">
                  <td className="job-signal">
                    {job.last_signal.signal_name === 'complete' && (
                      <i className="job-complete bi bi-check-circle-fill"></i>
                    )}
                    {job.last_signal.signal_name === 'error' && (
                      <i className="job-error bi bi-x-circle-fill"></i>
                    )}
                    {job.last_signal.signal_name === 'executing' && (
                      <ScaleLoader color="primary" />
                    )}
                  </td>
                  <td>
                    {
                      <Link to={`/site/${job.site}`} className="flex flex-row">
                        {job.site_name}
                        <i className="bi bi-box-arrow-in-up-right"></i>
                      </Link>
                    }
                  </td>
                  <td className="job-name mr-5">{job.name}</td>
                  <td className="time-distance">
                    <div className="flex flex-row align-items-center mb-5">
                      <i className="bi bi-calendar"></i>
                      <div>
                        {formatDistanceToNow(job.created_at, {
                          addSuffix: true,
                        })}
                      </div>
                    </div>
                    <div className="flex flex-row align-items-center">
                      <i className="bi bi-stopwatch-fill"></i>
                      <div>
                        {(job.finished_at &&
                          formatDuration(
                            intervalToDuration({
                              start: job.finished_at,
                              end: job.created_at,
                            }),
                            { locale: { ...locale, formatDistance } }
                          )) ||
                          'Running'}
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="hover-popup-footer flex flex-row justify-content-end">
          <Link to="/jobs">Show more...</Link>
        </div>
      </div>
    </div>
  );
};

const Header = () => {
  const navigate = useNavigate();
  return (
    <header className="App-header">
      <div className="container flex flex-row align-items-center justify-content-space-between">
        <div
          className="brand-space flex flex-row align-items-center"
          onClick={() => navigate('/')}
        >
          <img src={logo} alt="Acernis" />
          <span>Infrastructure Digital Twin</span>
        </div>
        <div className="flex flex-row">
          <JobNotifications />
          <DropDownMenu />
        </div>
      </div>
    </header>
  );
};

export default Header;
