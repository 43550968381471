import React, { useContext, useEffect } from 'react';
import './LoginPage.scss';
import logo from '../../assets/logo_small.png';
import { useLogin } from '../../services/auth';
import { AppContext } from '../../contexts/AppContextProvider';

const LoginPage = () => {
  const login = useLogin();
  const { setUser } = useContext(AppContext);

  useEffect(() => {
    setUser(null);
    localStorage.removeItem('user');
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    login(event.target.username.value, event.target.password.value);
  };

  return (
    <div className="login-page flex flex-column align-items-center justify-content-center full-height">
      <div className="login-box flex flex-column">
        <div className="flex flex-row align-items-center brand">
          <img src={logo} alt="Acernis" />
          <div>
            <h2>Acernis</h2>
            <h3>Infrastructure Digital Twin</h3>
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="field">
            <label>Username :</label>
            <input
              data-testid="username"
              type="text"
              name="username"
              id="username"
              required
              className="large"
            />
          </div>
          <div className="field">
            <label>Password :</label>
            <input
              data-testid="password"
              type="password"
              name="password"
              id="password"
              required
              className="large"
            />
          </div>

          <button type="submit" data-testid="log-in-button" className="box-button full-width-button">
            <span>Log In</span>
          </button>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
