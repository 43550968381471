import React from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import './Select.scss';
import PropTypes from 'prop-types';

const CustomSelect = ({ async, dataTestId, ...props }) => {
  return (
    <div data-testid={dataTestId}>
      {async && (
        <AsyncSelect
          {...props}
          className="custom-select-container"
          classNamePrefix="custom-select"
        />
      )}
      {!async && (
        <Select
          {...props}
          className="custom-select-container"
          classNamePrefix="custom-select"
        />
      )}
    </div>
  );
};

CustomSelect.propTypes = {
  async: PropTypes.bool,
  dataTestId: PropTypes.string,
};

export default CustomSelect;
