import React from 'react';
import { useEffect, useRef } from 'react';

/**
 * The checkbox used in our table.
 * Special treatment made for the indeterminate status of a checkbox.
 * React cannot handle it as regular props, so we have to use a Ref to manipulate directly it into the DOM.
 * And use the prop automaticaly calculated by the useRowSelect plugin for react table.
 */

/* eslint-disable react/prop-types */
const TableCheckbox = ({ indeterminate, ...rest }) => {
  const checkboxRef = useRef();

  useEffect(() => {
    checkboxRef.current.indeterminate = indeterminate;
  }, [indeterminate]);

  return (
    <input
      ref={checkboxRef}
      type="checkbox"
      {...rest}
      onClick={(e) => {
        e.stopPropagation();
      }}
    />
  );
};
/* eslint-enable react/prop-types */

export default TableCheckbox;
