import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Field } from '../../components/Field';
import { AppContext } from '../../contexts/AppContextProvider';
import { request } from '../../services/api';
import './ContactForm.scss';

const ContactForm = ({ contactTypes, handleSubmit }) => {
  const navigate = useNavigate();
  const { user } = useContext(AppContext);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [type, setType] = useState(contactTypes && contactTypes[0]);
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [name, setName] = useState('');
  const [isMobileNetworkOperator, setIsMobileNetworkOperator] = useState(false);
  const [isGeneralContractor, setIsGeneralContractor] = useState(false);
  const [isTowerCompany, setIsTowerCompany] = useState(false);

  const submit = async (event) => {
    event.preventDefault();
    let data = {
      phone: phoneNumber,
      contact_type: type.value,
      email,
      workspace: user.workspace_id,
    };

    if (type && type.value === 'IndividualContact') {
      data.first_name = firstName;
      data.last_name = lastName;
    } else if (type && type.value === 'CompanyContact') {
      data.name = name;
      data.is_a_Mobile_Network_Operator = isMobileNetworkOperator;
      data.is_a_General_Contractor = isGeneralContractor;
      data.is_a_Tower_Company = isTowerCompany;
    }

    const res = await request({
      url: `/workspaces/${user.workspace_id}/contacts/`,
      method: 'POST',
      data,
    });
    if (res.status === 201) {
      toast.success('Contact created. 👌');
      navigate('/contacts');
      handleSubmit();
    }
  };

  return (
    <div className="contact-form flex flex-column">
      <form onSubmit={submit}>
        <h3>New Contact</h3>
        <Field
          value={type}
          label="Type"
          placeholder="Select a contact type..."
          select
          required
          options={contactTypes}
          onChange={setType}
        />
        {type && type.value === 'IndividualContact' && (
          <>
            <Field
              label="First Name"
              placeholder="First Name"
              value={firstName}
              onChange={setFirstName}
              required
              isClearable
            />
            <Field
              label="Last Name"
              placeholder="Last Name"
              value={lastName}
              onChange={setLastName}
              required
              isClearable
            />
          </>
        )}
        {type && type.value === 'CompanyContact' && (
          <Field
            label="Name"
            placeholder="Company Name"
            value={name}
            onChange={setName}
            required
            isClearable
          />
        )}
        <Field
          label="Phone Number"
          placeholder="Phone Number"
          value={phoneNumber}
          onChange={setPhoneNumber}
          isClearable
        />
        <Field
          label="Email"
          placeholder="Contact Email"
          value={email}
          onChange={setEmail}
        />
        {type && type.value === 'CompanyContact' && (
          <>
            <Field
              type={'checkbox'}
              label="Is a mobile network operator ?"
              value={isMobileNetworkOperator}
              onChange={setIsMobileNetworkOperator}
            />
            <Field
              type={'checkbox'}
              label="Is a general contractor ?"
              value={isGeneralContractor}
              onChange={setIsGeneralContractor}
            />
            <Field
              type={'checkbox'}
              label="Is a tower company ?"
              value={isTowerCompany}
              onChange={setIsTowerCompany}
            />
          </>
        )}
        <div className="flex flex-row justify-content-end">
          <button type="submit" className="mb-20">
            Go
          </button>
        </div>
      </form>
    </div>
  );
};

ContactForm.propTypes = {
  contactTypes: PropTypes.array,
  handleSubmit: PropTypes.func,
};

export default ContactForm;
