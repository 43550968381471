import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { AppContext } from '../../contexts/AppContextProvider';
import SiteForm from '../../pages/Site/SiteForm';
import { useRequest } from '../../services/api';

const SiteFormContainer = ({ site, getSite }) => {
  const { user } = useContext(AppContext);
  const { data: contacts, loading } = useRequest(
    `/workspaces/${user.workspace_id}/contacts/`
  );
  return (
    <SiteForm
      handleSubmit={(updatedSite) => getSite(updatedSite.id)}
      site={site}
      contacts={contacts && contacts.results}
      contactsLoading={loading}
    />
  );
};

SiteFormContainer.propTypes = {
  site: PropTypes.object,
  setCurrentSite: PropTypes.func,
  getSite: PropTypes.func,
};

export default SiteFormContainer;
