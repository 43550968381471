import React, { useContext, useEffect } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import { IdtContext } from '../../contexts/IdtContextProvider';
import SiteComponentDetail from '../../pages/SiteComponent/SiteComponentDetail/SiteComponentDetail';
import SiteComponentForm from '../../pages/SiteComponent/SiteComponentForm';

const SiteComponentContainer = () => {
  const { currentComponent, getComponent, enums } = useContext(IdtContext);
  const { siteId, componentId } = useParams();

  useEffect(() => {
    getComponent(siteId, componentId);
    return () => getComponent(null);
  }, []);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <SiteComponentDetail
            siteComponent={currentComponent}
            getComponent={getComponent}
          />
        }
      />
      <Route
        path="/edit"
        element={
          <SiteComponentForm
            siteComponent={currentComponent}
            enums={enums}
            siteId={siteId}
            handleSubmit={(updatedComponent) =>
              getComponent(siteId, updatedComponent.id)
            }
          />
        }
      />
    </Routes>
  );
};

export default SiteComponentContainer;
