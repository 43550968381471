import React from 'react';
import PropTypes from 'prop-types';
import './Field.scss';
import Button from '../Button';

const SearchField = ({
  className,
  id,
  label,
  required,
  disabled,
  dataTestId,
  value,
  onChange,
  onSearch,
  placeholder,
  loading,
}) => {
  return (
    <div
      className={
        className ? `form-field search-form-field ${className}` : 'form-field'
      }
    >
      <label htmlFor={id}>{`${label}${required ? ' *' : ''}`}</label>
      <div className="flex flex-row align-items-center">
        <input
          disabled={disabled}
          className="field-input"
          id={id}
          data-testid={dataTestId}
          value={value}
          onChange={(event) => onChange(event.target.value)}
          placeholder={placeholder}
        />
        <Button
          onClick={(event) => {
            onSearch();
            event.preventDefault();
          }}
          loading={loading}
          dataTestId={"go-button"}
          className={'field-input-button'}
        >
          <span>Go</span>
        </Button>
      </div>
    </div>
  );
};

SearchField.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  required: PropTypes.bool,
  select: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  loading: PropTypes.bool,
  dataTestId: PropTypes.string,
};

export { SearchField };
