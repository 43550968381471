import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Field } from '../../../components/Field';
import RadioGroup from '../../../components/RadioGroup';
import { IdtContext } from '../../../contexts/IdtContextProvider';
import { request } from '../../../services/api';
import './SiteComponentForm.scss';

const componentTypeOptions = [
  {
    value: 'AntennaComponent',
    label: 'Antenna',
  },
  {
    value: 'LatticeTowerComponent',
    label: 'Lattice Tower',
  },
  {
    value: 'RooftopComponent',
    label: 'Rooftop',
  },
  {
    value: 'TechnicalComponent',
    label: 'Technical Component',
  },
  {
    value: 'Component',
    label: 'Other',
  },
];

const SiteComponentForm = ({ site, defaults = null }) => {
  const [name, setName] = useState('');
  const [status, setStatus] = useState(defaults && defaults.resourcetype);
  const [resourceType, setResourceType] = useState(
    (defaults &&
      defaults.resourcetype &&
      componentTypeOptions.find(
        (component) => component.value === defaults.resourcetype
      )) ||
      componentTypeOptions[2]
  );
  const { enums } = useContext(IdtContext);
  const navigate = useNavigate();

  const submitNewComponent = async (event) => {
    event.preventDefault();
    const res = await request({
      url: `/sites/${site.id}/components/`,
      method: 'POST',
      data: {
        name,
        status: status.value,
        site: site.id,
        component_type: resourceType.value,
      },
    });
    if (res.status === 201) {
      toast.success('Component correctly created. 👌');
      navigate(`/site/${site.id}/component/${res.data.id}`);
    }
  };

  return (
    <div className="flex flex-column new-component-form">
      <h3>New component for {site.name}</h3>
      <form onSubmit={submitNewComponent}>
        <div className="fields">
          <Field
            label="Name"
            placeholder="Component Name"
            value={name}
            onChange={setName}
            required
          />
          <Field
            value={status}
            label="Status"
            placeholder="Select a component status..."
            select
            isClearable
            options={enums && enums.installation_status}
            onChange={setStatus}
            className="last-field"
          />

          <RadioGroup
            label="Select component type :"
            options={componentTypeOptions}
            value={resourceType}
            onSelect={setResourceType}
          />
        </div>
        <div className="flex flex-row justify-content-end">
          <button type="submit">Go</button>
        </div>
      </form>
    </div>
  );
};

SiteComponentForm.propTypes = {
  site: PropTypes.object,
  defaults: PropTypes.object,
};

export default SiteComponentForm;
