import PropTypes from 'prop-types';
import React, { createContext, useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ProgressBar from '../components/ProgressBar';
import { request } from '../services/api';

const AppContext = createContext({
  tasks: [],
  user: undefined,
  setUser: () => {},
  isAdmin: () => false,
});

const AppContextProvider = ({ children }) => {
  let stored = localStorage.getItem('user');
  if (stored === null || stored.length == 0) {
    stored = undefined;
  }
  const [user, setUser] = useState(stored ? JSON.parse(stored) : undefined);
  const [loading, setLoading] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [isJobsLoading, setIsJobsLoading] = useState(false);

  const navigate = useNavigate();

  const getJobs = async (page) => {
    setIsJobsLoading(true);
    const jobs = await request(`/jobs/${(page && '?page=' + page) || ''}`);
    const finalJobs = jobs.data.results.map((job) => {
      return {
        site: job.site,
        site_name: job.site_name,
        created_at: new Date(
          job.signals.find((signal) => {
            return signal.signal_name === 'executing';
          }).created_at
        ),
        finished_at:
          (job.is_finished &&
            new Date(
              job.signals.find((signal) => {
                return ['complete', 'error'].includes(signal.signal_name);
              }).created_at
            )) ||
          null,
        name: job.name,
        signals: job.signals,
        last_signal: job.signals.reduce((a, b) => {
          return new Date(a.created_at) > new Date(b.created_at) ? a : b;
        }),
      };
    });
    setJobs(finalJobs);
    setIsJobsLoading(false);
  };

  useEffect(() => {
    if (!user) {
      navigate('/login');
    } else {
      getJobs();
    }
  }, [user]);

  return (
    <AppContext.Provider
      value={{
        user,
        setUser,
        isAdmin: user?.is_staff === true,
        setLoading,
        isJobsLoading,
        jobs,
        getJobs,
      }}
    >
      <ProgressBar loading={loading} />
      {children}
    </AppContext.Provider>
  );
};

AppContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { AppContext, AppContextProvider };
