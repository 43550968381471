import React, { useContext } from 'react';
import { useState } from 'react';
import { AppContext } from '../../contexts/AppContextProvider';
import { IdtContext } from '../../contexts/IdtContextProvider';
import Dashboard from '../../pages/Dashboard';
import { request, useRequest } from '../../services/api';

const DashboardContainer = () => {
  const { user } = useContext(AppContext);
  const { data: contacts, loading: contactsLoading } = useRequest(
    `/workspaces/${user.workspace_id}/contacts/`
  );
  const { data: antennaModels, loading: antennaModelsLoading } = useRequest(
    '/conf/models/antennas/'
  );
  const { enums } = useContext(IdtContext);

  const [sites, setSites] = useState([]);
  const [sitesLoading, setSitesLoading] = useState(false);
  const [sitesPageNumber, setSitesPageNumber] = useState(null);

  const getSites = async (page) => {
    setSitesLoading(true);
    setSites([]);
    const response = await request(`/sites/?page=${page}`);
    if (response && response.status === 200) {
      setSites(response.data.results);
      setSitesPageNumber(Math.ceil(response.data.count / 20));
    }
    setSitesLoading(false);
  };

  return (
    <Dashboard
      sites={sites}
      sitesLoading={sitesLoading}
      sitesPageNumber={sitesPageNumber}
      getSites={getSites}
      contacts={contacts && contacts.results}
      contactsLoading={contactsLoading}
      antennaModels={antennaModels && antennaModels.results}
      antennaModelsLoading={antennaModelsLoading}
      enums={enums}
    />
  );
};

export default DashboardContainer;
