import React, { useState } from 'react';
import Table from '../../components/Table';
import PropTypes from 'prop-types';
import Modal from '../../components/Modal';
import AntennaModelForm from './AntennaModelForm';

const AntennaModelList = ({ antennaModels, antennaModelsLoading, enums }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const modelsColumns = [
    {
      Header: 'Reference',
      accessor: 'reference',
    },
    {
      Header: 'Manufacturer',
      accessor: 'manufacturer',
      Cell: ({ value: manufacturer }) =>
        manufacturer && manufacturer.company_name,
    },
    {
      Header: 'Type',
      accessor: 'type',
    },
    {
      Header: 'Height/Thick/Diam',
      accessor: 'diameter',
      Cell: ({ row }) => {
        const originalRow = row.original;
        return `${originalRow.height || ' - '}/${
          originalRow.thickness || ' - '
        }/${originalRow.diameter || ' - '}`;
      },
    },
  ];

  return (
    <div className="flex justify-content-center">
      <div className="container">
        <div className="flex flex-row justify-content-space-between align-items-center">
          <h2> Antenna models </h2>
          <button className="button-icon" onClick={() => setIsModalOpen(true)}>
            <span> New Model </span>
            <i className="bi-plus-lg"></i>
          </button>
        </div>
        <Table
          columns={modelsColumns}
          data={antennaModels || []}
          loading={antennaModelsLoading}
        />
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => {
          setIsModalOpen(false);
        }}
      >
        <AntennaModelForm
          antennaTypes={enums && enums.antenna_types}
          handleSubmit={() => setIsModalOpen(false)}
        />
      </Modal>
    </div>
  );
};

AntennaModelList.propTypes = {
  antennaModels: PropTypes.array,
  antennaModelsLoading: PropTypes.bool,
  enums: PropTypes.object,
};

export default AntennaModelList;
