import PropTypes from 'prop-types';
import React, { createContext, useContext, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { request, useRequest } from '../services/api';
import { AppContext } from './AppContextProvider';

const IdtContext = createContext();

const IdtContextProvider = ({ children }) => {
  const { user, setLoading } = useContext(AppContext);
  const { data: enums } = useRequest('/conf/enums/');
  const [currentSite, setCurrentSite] = useState(null);
  const [currentComponent, setCurrentComponent] = useState(null);
  const [siteComponents, setSiteComponents] = useState([]);

  const saveComponent = async (data, id) => {
    let method = 'POST';
    let url = `/sites/${currentSite.id}/components/`;

    if (id) {
      method = 'PATCH';
      url = `/sites/${currentSite.id}/components/${id}/`;
    }

    const res = await request({
      url,
      method,
      data,
    });
    if (res.status === 201) {
      toast.success('Component correctly created. 👌');
      getComponent(currentSite.id, id);
    } else if (res.status === 200) {
      toast.success('Component correctly updated. 👌');
      getComponent(currentSite.id, id);
    }
    return res;
  };

  const getComponent = async (siteId, id) => {
    if (id) {
      setLoading(true);
      const { data: siteComponent } = await request(
        `/sites/${siteId}/components/${id}/`
      );
      setCurrentComponent(siteComponent);
      setLoading(false);
    } else {
      setCurrentComponent(null);
    }
  };

  const getSite = async (siteId) => {
    if (siteId) {
      setLoading(true);
      const { data: site } = await request(`/sites/${siteId}/`);
      setCurrentSite(site);
      setLoading(false);
    } else {
      setCurrentSite(null);
    }
  };

  const getSiteComponents = async (id, params) => {
    if (id) {
      setSiteComponents({ loading: true });
      const { data: components } = await request({
        url: `/sites/${id}/components/`,
        params,
      });
      setSiteComponents(components);
    } else {
      setSiteComponents([]);
    }
  };

  const deleteBulkSiteComponents = async (id, components) => {
    let baseSiteComponents = structuredClone(siteComponents);
    if (components && components.length > 0) {
      setSiteComponents({ loading: true });
      const response = await request({
        url: `/sites/${id}/components/`,
        method: 'delete',
        data: { components: components.map((component) => component.id) },
      });
      if (response.status === 204) {
        setSiteComponents({ loading: true });
      } else {
        setSiteComponents(baseSiteComponents);
      }
    }
  };

  return (
    <IdtContext.Provider
      value={{
        enums,
        currentSite,
        getSite,
        currentComponent,
        getComponent,
        siteComponents,
        getSiteComponents,
        deleteBulkSiteComponents,
        saveComponent,
      }}
    >
      {!user && <Navigate to="/login" replace={true} />}
      {user && children}
    </IdtContext.Provider>
  );
};

IdtContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { IdtContext, IdtContextProvider };
