import React, { useContext, useEffect, useState } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { IdtContext } from '../../contexts/IdtContextProvider';
import ExternalSiteAntennaDetail from '../../pages/SiteComponent/External/SiteAntennaDetail/ExternalSiteAntennaDetail';
import { request } from '../../services/api';

const ExternalSiteAntennaContainer = () => {
  const [antennaModels, setAntennaModels] = useState([]);
  const [antennaBands, setAntennaBands] = useState([]);
  const [antennaBandsLoading, setAntennaBandsLoading] = useState(false);

  const { currentSite, currentComponent, getComponent, enums } =
    useContext(IdtContext);
  const { siteId, antennaId } = useParams();

  const getAntennaModels = async () => {
    const response = await request('/conf/models/antennas');
    if (response && response.status === 200) {
      setAntennaModels(response.data.results);
    }
  };

  const getAntennaBands = async () => {
    setAntennaBandsLoading(true);
    const response = await request(
      `/sites/${siteId}/components/${antennaId}/antenna-bands`
    );
    if (response && response.status === 200) {
      setAntennaBands(response.data.results);
    }
    setAntennaBandsLoading(false);
  };

  const saveAntennaBand = async (data, id) => {
    let method = 'POST';
    let url = `/sites/${siteId}/components/${antennaId}/antenna-bands/`;
    if (id) {
      method = 'PATCH';
      url = `/sites/${siteId}/components/${antennaId}/antenna-bands/${id}/`;
    }
    const response = await request({ method, url, data });
    if (response && response.status === 200) {
      toast.success('Antenna band updated. 👌');
      getAntennaBands();
    } else if (response && response.status === 201) {
      toast.success('Antenna band created. 👌');
      getAntennaBands();
    }
    return response;
  };

  const deleteAntennaBand = async (antennaBandId) => {
    let method = 'DELETE';
    let url = `/sites/${siteId}/components/${antennaId}/antenna-bands/${antennaBandId}`;
    const response = await request({ method, url });
    if (response && response.status === 204) {
      toast.success('Antenna band deleted. 👌');
      getAntennaBands();
    }
    setAntennaBandsLoading(false);
    return response;
  };

  const saveComponent = async (data, id) => {
    let method = 'POST';
    let url = `/sites/${currentSite.id}/components/`;

    if (id) {
      method = 'PATCH';
      url = `/sites/${siteId}/components/${id}/`;
    }

    const res = await request({
      url,
      method,
      data,
    });
    if (res.status === 201) {
      toast.success('Component correctly created. 👌');
      getComponent(siteId, antennaId);
    } else if (res.status === 200) {
      toast.success('Component correctly updated. 👌');
      getComponent(siteId, antennaId);
    }
    return res;
  };

  useEffect(() => {
    getComponent(siteId, antennaId);
    getAntennaModels();
    getAntennaBands();
    return () => getComponent(null);
  }, []);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <ExternalSiteAntennaDetail
            siteComponent={currentComponent}
            antennaBands={antennaBands}
            antennaModels={antennaModels}
            enums={enums}
            site={currentSite}
            saveAntennaBand={saveAntennaBand}
            antennaBandsLoading={antennaBandsLoading}
            deleteAntennaBand={deleteAntennaBand}
            saveAntenna={saveComponent}
          />
        }
      />
    </Routes>
  );
};

export default ExternalSiteAntennaContainer;
