import React, { useContext, useEffect } from 'react';
import {
  Link,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import Breadcrumbs from '../../../components/Breadcrumbs';
import Tabs from '../../../components/Tabs/Tabs';
import { AppContext } from '../../../contexts/AppContextProvider';
import { IdtContext } from '../../../contexts/IdtContextProvider';
import ExternalSiteAntennaContainer from '../../../layout/ExternalSiteAntennaContainer';
import SiteComponentContainer from '../../../layout/SiteComponentContainer1';
import SiteFormContainer from '../../../layout/SiteFormContainer/SiteFormContainer';
import UnidentifiedSiteComponentsContainer from '../../../layout/UnidentifiedSiteComponentsContainer/UnidentifiedSiteComponentsContainer';
import { SiteComponentCreate } from '../../SiteComponent/SiteComponentForm';
import ExternalSiteAntennas from './External/ExternalSiteAntennas';
import GeneralDetails from './GeneralDetails';
import SiteComponentsDetails from './SiteComponentsDetails';
import './SiteDetail.scss';

const SiteDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { siteId } = useParams();

  const {
    currentSite,
    getSite,
    siteComponents,
    getSiteComponents,
    enums,
    deleteBulkSiteComponents,
    currentComponent,
    saveComponent,
  } = useContext(IdtContext);

  const { isAdmin } = useContext(AppContext);

  const currentPath = location.pathname.split('/')[3];

  useEffect(() => {
    if (siteId) getSite(siteId);
    return () => getSite(null);
  }, [siteId]);

  return (
    <div className="site-detail flex flex-column">
      {currentSite && (
        <>
          <div className="detail-header">
            <div className="linear-gradient">
              <div className="flex flex-row justify-content-center">
                <div className="container">
                  <div className="flex flex-row justify-content-space-between align-items-center">
                    <div className="flex flex-row">
                      <div
                        className={`flex flex-row site-name ${
                          !currentComponent && 'strong'
                        }`}
                      >
                        <Link to={`general`}>{currentSite.name}</Link>
                      </div>
                      {currentComponent && (
                        <>
                          <div
                            className={`flex flex-row align-items-center component-name ${
                              currentComponent && 'strong'
                            }`}
                          >
                            <i className="bi-chevron-right"></i>
                            <div>{currentComponent.name}</div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  {[
                    'general',
                    'antenna',
                    'components',
                    'unidentified-components',
                  ].includes(currentPath) && (
                    <Tabs
                      items={[
                        { path: 'general', label: 'General' },
                        isAdmin && {
                          path: 'components',
                          label: 'Components',
                        },
                        isAdmin &&
                          currentSite.unidentified_components_count > 0 && {
                            path: 'unidentified-components',
                            label: 'Identification',
                            counter: currentSite.unidentified_components_count,
                          },
                        !isAdmin && {
                          path: 'antenna',
                          label: 'Antennas',
                        },
                      ]}
                      currentPath={currentPath}
                      onSelect={(path) => navigate(path)}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-content-center">
            <div className="container">
              <div className="flex flex-row">
                <div className="site-content flex flex-column full-width">
                  <Breadcrumbs separator={<i className="bi-chevron-right" />}>
                    <Link to="/">
                      <i className="bi-house-door" />
                    </Link>
                    <Link to="general">{currentSite.name}</Link>
                    {currentComponent && (
                      <>
                        {isAdmin && <Link to="components">Components</Link>}
                        {!isAdmin && <Link to="antenna">Antennas</Link>}
                      </>
                    )}
                    {currentComponent && <div>{currentComponent.name}</div>}
                  </Breadcrumbs>
                  <Routes>
                    <Route
                      exact
                      path=""
                      element={<Navigate replace to="general" />}
                    />
                    <Route
                      path="general"
                      element={
                        <GeneralDetails site={currentSite} getSite={getSite} />
                      }
                    />
                    <Route
                      path="components"
                      element={
                        <SiteComponentsDetails
                          componentTypes={enums && enums.component_types}
                          technicalComponentUserTags={
                            enums && enums.technical_component_user_tags
                          }
                          installationStatus={
                            enums && enums.installation_status
                          }
                          site={currentSite}
                          siteComponents={siteComponents}
                          getSiteComponents={getSiteComponents}
                          deleteBulkSiteComponents={deleteBulkSiteComponents}
                        />
                      }
                    />
                    <Route
                      path="unidentified-components"
                      element={
                        <UnidentifiedSiteComponentsContainer
                          site={currentSite}
                        />
                      }
                    />
                    <Route
                      path="component/new"
                      element={<SiteComponentCreate site={currentSite} />}
                    />
                    <Route
                      path="component/:componentId/*"
                      element={<SiteComponentContainer />}
                    />
                    <Route
                      path="edit"
                      element={
                        <SiteFormContainer
                          site={currentSite}
                          getSite={getSite}
                        />
                      }
                    />
                    <Route
                      path="antenna"
                      element={
                        <ExternalSiteAntennas
                          componentTypes={enums && enums.component_types}
                          technicalComponentTypes={
                            enums && enums.technical_component_types
                          }
                          site={currentSite}
                          installationStatus={
                            enums && enums.installation_status
                          }
                          siteComponents={siteComponents}
                          getSiteComponents={getSiteComponents}
                          deleteBulkSiteComponents={deleteBulkSiteComponents}
                          saveAntenna={saveComponent}
                        />
                      }
                    />
                    <Route
                      path="antenna/:antennaId/*"
                      element={<ExternalSiteAntennaContainer />}
                    />
                  </Routes>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SiteDetail;
