import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Field } from '../../../../components/Field';
import './ExternalSiteAntennaForm.scss';

const ExternalSiteAntennaForm = ({
  antenna,
  site,
  antennaModels = [],
  handleSubmit,
}) => {
  const [name, setName] = useState('');
  const [antennaModel, setAntennaModel] = useState(null);
  const [positionX, setPositionX] = useState('');
  const [positionY, setPositionY] = useState('');
  const [positionZ, setPositionZ] = useState('');
  const [azimuth, setAzimuth] = useState('');
  const [mechanicalDowntilt, setMechanicalDowntilt] = useState('');

  const submitAntenna = async (event) => {
    event.preventDefault();

    let data = {
      name,
      status: 'INSTALLED',
      site: site.id,
      component_type: 'AntennaComponent',
      // model: antennaModel.value,
    };

    if (positionX || positionY || positionZ) {
      data.position = {
        x: parseFloat(positionX),
        y: parseFloat(positionY),
        z: parseFloat(positionZ),
      };
    }

    if (azimuth || mechanicalDowntilt) {
      data.azimuth = parseFloat(azimuth);
      data.mechanical_downtilt = parseFloat(mechanicalDowntilt);
    }

    handleSubmit(data, antenna && antenna.id);
  };

  useEffect(() => {
    if (antenna) {
      setName(antenna.name);

      if (antenna.position) {
        setPositionX(antenna.position.x && antenna.position.x.toFixed(3));
        setPositionY(antenna.position.y && antenna.position.y.toFixed(3));
        setPositionZ(antenna.position.z && antenna.position.z.toFixed(3));
      }
      setAzimuth(antenna.azimuth && antenna.azimuth.toFixed(3));
      setMechanicalDowntilt(antenna.mechanical_downtilt && antenna.mechanical_downtilt.toFixed(3));
      // setAntennaModel(antennaModels.find((option) => antenna.id === option.id));
    }
  }, []);

  return (
    <div className="flex flex-column new-component-form">
      {!antenna && <h3>New antenna for {site.name}</h3>}
      {antenna && <h3>Update antenna for {site.name}</h3>}
      <form onSubmit={submitAntenna}>
        <div className="fields">
          <Field
            label="Name"
            placeholder="Antenna Name"
            value={name}
            onChange={setName}
            required
          />
          <Field
            value={antennaModel}
            label="Model"
            placeholder="Select a component model..."
            select
            isClearable
            options={antennaModels.map((antennaModel) => {
              return { label: antennaModel.reference, value: antennaModel.id };
            })}
            onChange={setAntennaModel}
          />
          <Field
            label="Position X"
            placeholder="Example : 352.834"
            value={positionX}
            onChange={setPositionX}
            type="number"
          />
          <Field
            label="Position Y"
            placeholder="Example : 352.834"
            value={positionY}
            onChange={setPositionY}
            type="number"
          />
          <Field
            label="Position Z"
            placeholder="Example : 352.834"
            value={positionZ}
            onChange={setPositionZ}
            type="number"
          />
          <Field
            label="Azimuth [°]"
            placeholder="Example : 34.8"
            value={azimuth}
            onChange={setAzimuth}
            type="number"
          />
          <Field
            label="Mech. downtilt [°]"
            placeholder="Example : 34.8"
            value={mechanicalDowntilt}
            onChange={setMechanicalDowntilt}
            type="number"
          />
        </div>
        <div className="flex flex-row justify-content-end">
          <button type="submit">Go</button>
        </div>
      </form>
    </div>
  );
};

ExternalSiteAntennaForm.propTypes = {
  site: PropTypes.object,
  defaults: PropTypes.object,
  antenna: PropTypes.object,
  antennaModels: PropTypes.array,
  handleSubmit: PropTypes.func,
};

export default ExternalSiteAntennaForm;
