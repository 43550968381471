import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './RadioGroup.scss';

const RadioGroup = function ({
  label,
  options = [],
  name = 'select_option',
  onSelect,
  value,
}) {
  const [selected, setSelected] = useState(value);

  const handleSelect = (value) => {
    setSelected(value);
    onSelect(value);
  };

  return (
    <div className="radio-group">
      <p>{label}</p>
      {options.map((option, index) => (
        <div key={index} className="radio-input flex align-items-center">
          <input
            type="radio"
            id={option.value}
            name={name}
            value={option.value}
            checked={selected.value === option.value}
            data-testid={option.dataTestId || ''}
            onChange={() => handleSelect(option)}
          />
          <label htmlFor={option.value}>{option.label}</label>
        </div>
      ))}
    </div>
  );
};

RadioGroup.propTypes = {
  label: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.object,
  name: PropTypes.string,
  onSelect: PropTypes.func,
};

export default RadioGroup;
