import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { RotationsCard } from '../../../../components/Cards';
import { CoordinatesCard } from '../../../../components/Cards/CoordinatesCard';
import Chip from '../../../../components/Chip';
import ConfirmPopup from '../../../../components/ConfirmPopup/ConfirmPopup';
import Item from '../../../../components/Item';
import Modal from '../../../../components/Modal';
import Table from '../../../../components/Table';
import { IdtContext } from '../../../../contexts/IdtContextProvider';
import { request } from '../../../../services/api';
import AntennaBandForm from '../../../AntennaBand/AntennaBandForm';
import ExternalSiteAntennaForm from '../SiteAntennaForm/ExternalSiteAntennaForm';
import './ExternalSiteAntennaDetail.scss';

const ExternalSiteAntennaDetail = ({
  site,
  siteComponent,
  antennaBands,
  saveAntennaBand,
  antennaBandsLoading,
  deleteAntennaBand,
  saveAntenna,
  antennaModels,
}) => {
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [isAntennaBandFormOpen, setIsAntennaBandFormOpen] = useState(false);
  const [isAntennaFormOpen, setIsAntennaFormOpen] = useState(false);
  const [antennaBandSelected, setAntennaBandSelected] = useState(null);
  const [confirmDeleteBandOpen, setConfirmDeleteBandOpen] = useState(false);
  const navigate = useNavigate();
  const { enums } = useContext(IdtContext);

  const deleteComponent = async (componentId) => {
    const res = await request({
      url: `/sites/${siteComponent.site}/components/${componentId}`,
      method: 'DELETE',
    });
    if (res.status === 204) toast.success('Component correctly deleted. 👌');
    navigate(`/site/${siteComponent.site}/antenna`);
  };

  const handleAntennaSave = async (data, id) => {
    const response = await saveAntenna(data, id);
    if (response.status === 200) {
      setIsAntennaFormOpen(false);
    }
  };

  const antennaBandsColumns = [
    { Header: 'Port', accessor: 'electrical_port' },
    { Header: 'Frequency [Mhz]', accessor: 'frequency' },
    { Header: 'Power [W]', accessor: 'power' },
    { Header: 'Attenuation [dB]', accessor: 'attenuation' },
    { Header: 'Electrical down-tilt [°]', accessor: 'electrical_down_tilt' },
    {
      Header: 'Active ?',
      accessor: 'is_activated',
      // eslint-disable-next-line react/prop-types
      Cell: ({ value }) => {
        return value && <i className="bi bi-check2"></i>;
      },
    },
    {
      Header: '',
      accessor: 'id',
      headerClassName: 'actions-table-header',
      // eslint-disable-next-line react/prop-types
      Cell: ({ row }) => (
        <div className="flex flex-row table-actions">
          <button
            className="button full-width-button mr-10"
            onClick={() => {
              setIsAntennaBandFormOpen(true);
              // eslint-disable-next-line react/prop-types
              setAntennaBandSelected(row.original);
            }}
          >
            <i className="bi bi-pencil"></i>
          </button>
          <button
            className="button outlined-button full-width-button"
            onClick={() => {
              setConfirmDeleteBandOpen(true);
              // eslint-disable-next-line react/prop-types
              setAntennaBandSelected(row.original);
            }}
          >
            <i className="bi bi-x"></i>
          </button>
        </div>
      ),
    },
  ];

  const handleAntennaBandSubmit = async (data, id) => {
    const response = await saveAntennaBand(data, id);
    if ([200, 201].includes(response.status)) {
      setIsAntennaBandFormOpen(false);
      setAntennaBandSelected(null);
    }
  };

  const handleDeleteAntennaBand = async (id) => {
    const response = await deleteAntennaBand(id);
    if (response.status === 204) {
      setConfirmDeleteBandOpen(false);
      setAntennaBandSelected(null);
    }
  };

  return (
    <div className="general-details flex flex-column justify-content-center">
      {siteComponent && (
        <>
          <div className="flex flex-row justify-content-end button-group">
            <button
              className="button"
              onClick={() => setIsAntennaFormOpen(true)}
            >
              Update
            </button>
            <button
              className="outlined-button"
              onClick={() => {
                setConfirmDeleteOpen(true);
              }}
            >
              Delete
            </button>
          </div>
          <div className="flex flex-row mb-20">
            <Chip
              content={
                (enums &&
                  enums.component_types.find(
                    (component_type) =>
                      component_type.value === siteComponent.component_type
                  )?.label) ||
                'Undefined'
              }
            />
          </div>
          <div className="flex flex-column">
            <Item label={'Name'} value={siteComponent.name} />
            {siteComponent.component_type === 'AntennaComponent' && (
              <Item label={'Status'} value={siteComponent.status} />
            )}
            {siteComponent.component_type === 'TechnicalComponent' && (
              <Item
                label={'Geometric representation'}
                value={siteComponent.type}
              />
            )}
            {siteComponent.component_type === 'AntennaComponent' && (
              <Item
                label={'Antenna Model'}
                value={siteComponent.model && siteComponent.model.reference}
              />
            )}
          </div>

          <div className="flex mb-20">
            <CoordinatesCard
              header="Position [m]"
              coordinates={siteComponent.position && siteComponent.position}
            />
            {siteComponent.component_type === 'TechnicalComponent' && (
              <CoordinatesCard
                header="Dimensions [mm]"
                coordinates={siteComponent.local_dimension}
              />
            )}
            <RotationsCard
              header="Orientation [°]"
              horizontal={siteComponent.azimuth}
              vertical={siteComponent.mechanical_downtilt}
            />
          </div>
          <div className="flex flex-row justify-content-space-between align-items-center">
            <h2>Antenna bands</h2>
            <button
              className="button full-width-button"
              onClick={() => setIsAntennaBandFormOpen(true)}
            >
              <i className="bi-plus-lg"></i>
            </button>
          </div>

          <Table
            columns={antennaBandsColumns}
            data={antennaBands}
            loading={antennaBandsLoading}
          />

          <div className="flex flex-column align-items-end meta-info">
            <div>
              Created :{' '}
              {siteComponent.created_at &&
                new Date(siteComponent.created_at)
                  .toISOString()
                  .split('T')[0]}{' '}
              By {siteComponent.created_by && siteComponent.created_by.username}
            </div>
            <div>
              Updated :{' '}
              {siteComponent.updated_at &&
                new Date(siteComponent.updated_at)
                  .toISOString()
                  .split('T')[0]}{' '}
              By {siteComponent.updated_by && siteComponent.updated_by.username}
            </div>
          </div>
          <ConfirmPopup
            isOpen={confirmDeleteOpen}
            headerMessage="Are you sure ?"
            bodyMessage={`You will permanently delete the antenna ${siteComponent.name}.`}
            handleConfirm={() => deleteComponent(siteComponent.id)}
            onRequestClose={() => setConfirmDeleteOpen(false)}
          />
          <ConfirmPopup
            isOpen={confirmDeleteBandOpen}
            headerMessage="Are you sure ?"
            bodyMessage={`You will permanently delete the antenna band on port ${
              antennaBandSelected && antennaBandSelected.electrical_port
            }.`}
            handleConfirm={() =>
              handleDeleteAntennaBand(antennaBandSelected.id)
            }
            onRequestClose={() => {
              setConfirmDeleteBandOpen(false);
              setAntennaBandSelected(null);
            }}
          />
          <Modal
            isOpen={isAntennaBandFormOpen}
            onRequestClose={() => {
              setAntennaBandSelected(null);
              setIsAntennaBandFormOpen(false);
            }}
          >
            <AntennaBandForm
              antennaBand={antennaBandSelected}
              saveAntennaBand={handleAntennaBandSubmit}
            />
          </Modal>
          <Modal
            isOpen={isAntennaFormOpen}
            onRequestClose={() => {
              setIsAntennaFormOpen(false);
            }}
          >
            <ExternalSiteAntennaForm
              site={site}
              antenna={siteComponent}
              handleSubmit={handleAntennaSave}
              antennaModels={antennaModels}
            />
          </Modal>
        </>
      )}
    </div>
  );
};

ExternalSiteAntennaDetail.propTypes = {
  siteComponent: PropTypes.object,
  getComponent: PropTypes.func,
  site: PropTypes.object,
  antennaBands: PropTypes.array,
  saveAntennaBand: PropTypes.func,
  antennaBandsLoading: PropTypes.bool,
  deleteAntennaBand: PropTypes.func,
  saveAntenna: PropTypes.func,
  antennaModels: PropTypes.array,
};

export default ExternalSiteAntennaDetail;
