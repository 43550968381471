import React, { useEffect, useState } from 'react';
import Modal from '../../components/Modal';
import PropTypes from 'prop-types';
import Button from '../Button';
import RadioGroup from '../RadioGroup';

const UploadFileModal = ({
  isOpen,
  handleUpload,
  onRequestClose,
  headerText,
  secondaryText,
  loading,
  pointCloudParams,
  fileUploadType,
}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [componentsPointCloudTypes, setComponentsPointCloudTypes] = useState(
    []
  );
  const [componentsPointCloudType, setComponentsPointCloudType] =
    useState(null);

  useEffect(() => {
    let componentsPointCloudOptions = [
      {
        value: 'AntennaComponentsClusters',
        label: 'Antennas',
        dataTestId: 'antennas',
      },
      {
        value: 'TechnicalComponentsClusters',
        label: 'Technical components',
        dataTestId: 'technical-components',
      },
      {
        value: 'RooftopComponentsClusters',
        label: 'Rooftop components',
        dataTestId: 'rooftop-components',
      },
      {
        value: 'WallComponentsClusters',
        label: 'Wall components',
        dataTestId: 'wall-components',
      }
    ];


    setComponentsPointCloudTypes(componentsPointCloudOptions);
    setComponentsPointCloudType(componentsPointCloudOptions[0]);
  }, [fileUploadType]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => {
        onRequestClose(false);
        setSelectedFile(null);
      }}
    >
      <div className="flex flex-column upload-modal">
        <h3>{headerText || 'Upload File'}</h3>
        <p className="secondary">{secondaryText}</p>
        <input
          id="upload-file"
          type="file"
          onChange={(event) => setSelectedFile(event.target.files[0])}
          data-testid="select-file-button"
        />
        <label
          htmlFor="upload-file"
          className="file-upload-button outlined-button"
        >
          Select a file...
        </label>
        {selectedFile && selectedFile.name && (
          <div className="selected-file-card flex flex-row align-items-center">
            <i className="bi-file-earmark-binary"></i>
            <span>{selectedFile.name}</span>
          </div>
        )}
        {!selectedFile && (
          <span className="secondary no-file">No files selected</span>
        )}
        {pointCloudParams && (
          <div className="mb-20">
            <RadioGroup
              label="Select file type :"
              options={componentsPointCloudTypes}
              value={componentsPointCloudType}
              onSelect={setComponentsPointCloudType}
            />
          </div>
        )}
        <div className="flex flex-row justify-content-end button-group">
          <button
            className={'button-link'}
            onClick={() => {
              onRequestClose(false);
              setSelectedFile(null);
            }}
          >
            Cancel
          </button>
          <Button
            onClick={() =>
              handleUpload(selectedFile, {
                componentsPointCloudType: componentsPointCloudType.value,
              })
            }
            loading={loading}
            dataTestId="upload-button"
          >
            <span>Upload</span>
          </Button>
        </div>
      </div>
    </Modal>
  );
};

UploadFileModal.propTypes = {
  isOpen: PropTypes.bool,
  handleUpload: PropTypes.func,
  onRequestClose: PropTypes.func,
  headerText: PropTypes.string,
  secondaryText: PropTypes.string,
  loading: PropTypes.bool,
  pointCloudParams: PropTypes.bool,
  fileUploadType: PropTypes.string,
};

export default UploadFileModal;
