import React from 'react';
import { Route, Routes } from 'react-router-dom';

import SiteDetail from '../../pages/Site/SiteDetail';
import './Main.scss';
import DashboardContainer from '../DashboardContainer';
import AntennaModelListContainer from '../AntennaModelListContainer';
import ContactListContainer from '../ContactListContainer';
import SiteFormContainer from '../SiteFormContainer';
import JobList from '../../pages/Jobs/JobList';

const Main = () => {
  return (
    <div className="Main">
      <Routes>
        <Route path="/" element={<DashboardContainer />} />
        <Route path="/site/new" element={<SiteFormContainer />} />
        <Route path="site/:siteId/*" element={<SiteDetail />} />
        <Route path="/antenna-models" element={<AntennaModelListContainer />} />
        <Route path="/contacts" element={<ContactListContainer />} />
        <Route path="/jobs" element={<JobList />} />
      </Routes>
    </div>
  );
};

export default Main;
