import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../contexts/AppContextProvider';
import { request } from './api';

export const useLogin = () => {
  const { setUser } = useContext(AppContext);
  const navigate = useNavigate();
  const login = async (username, password) => {
    const response = await request({
      url: '/auth/token/',
      method: 'post',
      data: {
        username,
        password,
      },
    }).catch((error) => {
      if (error.response) {
        console.log(error.response.status);
        console.log(error.response.data);
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log(error.message);
      }
    });
    if (response && response.data.token) {
      localStorage.setItem(
        'user',
        JSON.stringify({ username, ...response.data })
      );
      setUser({ username, ...response.data });
      navigate('/');
    }
  };
  return login;
};

export const useLogout = () => {
  const navigate = useNavigate();
  const { setUser } = useContext(AppContext);
  const logout = () => {
    setUser(null);
    localStorage.removeItem('user');
    navigate('/login');
  };

  return logout;
};

export const logout = () => {
  const navigate = useNavigate();
  const { setUser } = useContext(AppContext);
  setUser(null);
  localStorage.removeItem('user');
  navigate('/login');
};
