import React from 'react';
import PropTypes from 'prop-types';
import { ScaleLoader } from 'react-spinners';

const CustomScaleLoader = ({ color }) => {
  let finalColor;
  switch (color) {
    case 'primary':
      finalColor = '#1d496e';
      break;

    default:
      finalColor = 'white';
      break;
  }
  return (
    <div className="spinner">
      <ScaleLoader color={finalColor} height={16} width={2} margin={1} />
    </div>
  );
};

export { CustomScaleLoader as ScaleLoader };

CustomScaleLoader.propTypes = {
  color: PropTypes.string,
};
