import React from 'react';
import PropTypes from 'prop-types';
import './Cards.scss';

const RotationsCard = ({ header, horizontal, vertical }) => {
  return (
    <div className="coordinates-card">
      <h3>{header}</h3>
      <div className="coordinate">
        {'Azimuth : '}
        {(horizontal && horizontal.toFixed(2)) || <i className="bi-dash" />}
      </div>
      <div className="coordinate">
        {'Mech. downtilt : '}
        {(vertical && vertical.toFixed(2)) || <i className="bi-dash" />}
      </div>
    </div>
  );
};

RotationsCard.propTypes = {
  header: PropTypes.string,
  horizontal: PropTypes.number,
  vertical: PropTypes.number,
};

export { RotationsCard };
