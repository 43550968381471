import React from 'react';
import './App.scss';
import Header from './layout/Header';
import Main from './layout/Main';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { AppContextProvider } from './contexts/AppContextProvider';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import { IdtContextProvider } from './contexts/IdtContextProvider';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

const MainContainer = () => {
  return (
    <IdtContextProvider>
      <Header />
      <Main />
    </IdtContextProvider>
  );
};

function App() {
  return (
    <div className="App" id="App">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        pauseOnHover
        theme="colored"
      />
      <BrowserRouter>
        <AppContextProvider>
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="*" element={<MainContainer />} />
          </Routes>
        </AppContextProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
