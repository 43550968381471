import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { RotationsCard } from '../../../components/Cards';
import { CoordinatesCard } from '../../../components/Cards/CoordinatesCard';
import Chip from '../../../components/Chip';
import ConfirmPopup from '../../../components/ConfirmPopup/ConfirmPopup';
import Item from '../../../components/Item';
import PointCloudCard from '../../../components/PointCloudCard/PointCloudCard';
import { IdtContext } from '../../../contexts/IdtContextProvider';
import { request } from '../../../services/api';
import './SiteComponentDetail.scss';

const SiteComponentDetail = ({ siteComponent, getComponent }) => {
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const navigate = useNavigate();
  const { enums } = useContext(IdtContext);

  const deleteComponent = async (componentId) => {
    const res = await request({
      url: `/sites/${siteComponent.site}/components/${componentId}`,
      method: 'DELETE',
    });
    if (res.status === 204) toast.success('Component correctly deleted. 👌');
    navigate(`/site/${siteComponent.site}/components`);
  };

  const unignoreComponent = async (componentId) => {
    const res = await request({
      url: `/sites/${siteComponent.site}/components/${componentId}/`,
      method: 'PATCH',
      data: { deleted: false },
    });
    if (res.status === 200) {
      toast.success('Component correctly unignored. 👌');
      getComponent(siteComponent.site, siteComponent.id);
    }
  };

  return (
    <div className="general-details flex justify-content-center">
      <div className="container">
        {siteComponent && (
          <>
            <div className="flex flex-row gap-10 mb-20">
              <Chip
                content={
                  (enums &&
                    enums.component_types.find(
                      (component_type) =>
                        component_type.value === siteComponent.component_type
                    )?.label) ||
                  'Undefined'
                }
              />
              {siteComponent.component_type === 'TechnicalComponent' && (
                <Chip
                  content={
                    enums.technical_component_types.find(
                      (type) => siteComponent.type === type.value
                    )?.label || 'Undefined'
                  }
                />
              )}
              {siteComponent.is_deleted && (
                <Chip content={'Ignored'} outlined className="warning" />
              )}
            </div>
            <div className="flex flex-row">
              {siteComponent.isometric_view_image && (
                <div className="flex flex-column component-images mr-20">
                  <div className="flex flex-row main-image">
                    <img
                      alt="Isometric View"
                      title="Isometric View"
                      src={
                        siteComponent.isometric_view_image &&
                        siteComponent.isometric_view_image.file
                      }
                    ></img>
                  </div>
                  <div className="flex flex-row secondary-images">
                    {siteComponent.front_view_image && (
                      <div>
                        <img
                          alt="Front View"
                          title="Front View"
                          src={siteComponent.front_view_image.file}
                        ></img>
                      </div>
                    )}
                    {siteComponent.side_view_image && (
                      <div>
                        <img
                          alt="Side View"
                          title="Side View"
                          src={siteComponent.side_view_image.file}
                        ></img>
                      </div>
                    )}
                    {siteComponent.top_view_image && (
                      <div>
                        <img
                          alt="Top View"
                          title="Top View"
                          src={siteComponent.top_view_image.file}
                        ></img>
                      </div>
                    )}
                  </div>
                </div>
              )}
              <div className="flex flex-column full-width">
                <Item label={'Name'} value={siteComponent.name} />
                {siteComponent.component_type === 'AntennaComponent' && (
                  <Item label={'Status'} value={siteComponent.status} />
                )}
                {siteComponent.component_type === 'TechnicalComponent' && (
                  <Item label={'User tag'} value={siteComponent.type} />
                )}
                {siteComponent.component_type === 'AntennaComponent' && (
                  <Item
                    label={'Owner'}
                    value={siteComponent.owner && siteComponent.owner.full_name}
                  />
                )}
                <div className="flex flex-row button-group mb-20 mr-20">
                  {siteComponent.is_deleted && (
                    <button
                      className="button warning-button full-width-button"
                      onClick={() => unignoreComponent(siteComponent.id)}
                    >
                      <i className="bi bi-save"></i>
                    </button>
                  )}
                  <button
                    onClick={() => navigate('edit')}
                    className="full-width-button"
                  >
                    <i className="bi bi-pencil"></i>
                  </button>
                  <button
                    onClick={() => setConfirmDeleteOpen(true)}
                    className="full-width-button outlined-button"
                  >
                    <i className="bi bi-x-lg"></i>
                  </button>
                </div>
              </div>
            </div>
            <h2>Point Cloud information</h2>
            {siteComponent.type !== 'WALL' && (
              <div className="flex flex-column">
                <PointCloudCard
                  label={'Main point cloud'}
                  pointCloud={siteComponent.point_cloud}
                  className="mb-20"
                />
                {siteComponent.component_type === 'LatticeTowerComponent' && (
                  <>
                    <PointCloudCard
                      label={'Core point cloud'}
                      pointCloud={siteComponent.core_point_cloud}
                      className="mb-10"
                    />
                    <PointCloudCard
                      label={'Outer point cloud'}
                      pointCloud={siteComponent.outer_point_cloud}
                      className="mb-10"
                    />
                    <PointCloudCard
                      label={'Inner point cloud'}
                      pointCloud={siteComponent.inner_point_cloud}
                      className="mb-20"
                    />
                  </>
                )}
              </div>
            )}
            <div className="flex mb-20">
              <CoordinatesCard
                header="Position [m]"
                coordinates={siteComponent.position && siteComponent.position}
              />
              {siteComponent.component_type === 'TechnicalComponent' && (
                <>
                  <CoordinatesCard
                    header="Dimensions [mm]"
                    coordinates={siteComponent.local_dimension}
                  />
                </>
              )}
              <RotationsCard
                header="Orientation [°]"
                horizontal={siteComponent.azimuth && siteComponent.azimuth}
                vertical={
                  siteComponent.mechanical_downtilt &&
                  siteComponent.mechanical_downtilt
                }
              />
            </div>

            <div className="flex flex-column align-items-end meta-info">
              <div>
                Created :{' '}
                {siteComponent.created_at &&
                  new Date(siteComponent.created_at)
                    .toISOString()
                    .split('T')[0]}{' '}
                By{' '}
                {siteComponent.created_by && siteComponent.created_by.username}
              </div>
              <div>
                Updated :{' '}
                {siteComponent.updated_at &&
                  new Date(siteComponent.updated_at)
                    .toISOString()
                    .split('T')[0]}{' '}
                By{' '}
                {siteComponent.updated_by && siteComponent.updated_by.username}
              </div>
            </div>
            <ConfirmPopup
              isOpen={confirmDeleteOpen}
              headerMessage="Are you sure ?"
              bodyMessage={`You will permanently delete the component ${siteComponent.name}.`}
              handleConfirm={() => deleteComponent(siteComponent.id)}
              onRequestClose={() => setConfirmDeleteOpen(false)}
            />
          </>
        )}
      </div>
    </div>
  );
};

SiteComponentDetail.propTypes = {
  siteComponent: PropTypes.object,
  getComponent: PropTypes.func,
};

export default SiteComponentDetail;
