import React from 'react';
import PropTypes from 'prop-types';
import './ProgressBar.scss';

const ProgressBar = ({ loading }) => {
  return <div className={`progress-bar ${loading ? 'loading' : ''}`}></div>;
};

ProgressBar.propTypes = {
  loading: PropTypes.bool,
};

export default ProgressBar;
