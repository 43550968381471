import React from 'react';
import PropTypes from 'prop-types';
import './Item.scss';

const Item = ({ label, value, empty, fileDownload, dataTestId, className }) => {
  let finalClassName = fileDownload ? 'item file-download' : 'item';
  if (className) {
    finalClassName += ` ${className}`;
  }
  return (
    <div className={finalClassName}>
      {!fileDownload && (
        <>
          <strong className="label">{`${label} : `}</strong>

          <span className="value" data-testid={dataTestId}>
            {value || empty || <i className="bi-dash"></i>}
          </span>
        </>
      )}
      {fileDownload && (
        <a href={value}>
          <div className="label">{label}</div>
          {value && <i className="bi bi-download"></i>}
          {!value && <i className="bi bi-dash"></i>}
        </a>
      )}
    </div>
  );
};

Item.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]),
  dataTestId: PropTypes.string,
  empty: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  fileDownload: PropTypes.bool,
  className: PropTypes.string,
};

export default Item;
