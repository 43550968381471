import React from 'react';
import PropTypes from 'prop-types';
import './Tabs.scss';
import { Link } from 'react-router-dom';

const Tabs = ({ items, currentPath, onSelect }) => {
  const onIndexChanged = (index, item) => {
    onSelect(item.path, index);
  };

  return (
    <div className="tabs">
      <ul className="flex flex-row">
        {items.map(
          (item, index) =>
            item && (
              <li
                key={index}
                className={`tab ${item.path === currentPath ? 'active' : ''}`}
                onClick={() => onIndexChanged(index, item)}
              >
                <Link to={item.path} title={item.label}>
                  {item.label}
                </Link>
                {item.counter && (
                  <div className="tab-counter">{item.counter}</div>
                )}
              </li>
            )
        )}
      </ul>
    </div>
  );
};

Tabs.propTypes = {
  items: PropTypes.array,
  onSelect: PropTypes.func,
  activeIndex: PropTypes.number,
  currentPath: PropTypes.string,
};

export default Tabs;
