import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { request } from '../../../services/api';
import { Field } from '../../../components/Field';
import { ScaleLoader } from '../../../components/Spinner';
import { toast } from 'react-toastify';

const UnidentifiedComponentCard = ({
  component,
  identify,
  componentOptions,
  dismissed,
  handleDismiss,
}) => {
  const [typeSelected, setTypeSelected] = useState(null);
  const [statusLoading, setStatusLoading] = useState(false);

  const handleSetType = async (component, typeSelected) => {
    if (typeSelected) {
      setStatusLoading(true);
      setTypeSelected(typeSelected);
      await identify(component, typeSelected.value);
      setStatusLoading(false);
    } else {
      setTypeSelected(null);
    }
  };

  return (
    <div
      className={`flex flex-column unidentified-cards${
        dismissed ? ' dismissed' : ''
      }`}
    >
      <div className="button-group flex flex-row justify-content-end actions">
        <button
          className="button outlined-button"
          onClick={() => handleDismiss(component)}
        >
          {dismissed ? 'Undismiss' : 'Dismiss'}
        </button>
      </div>
      <div className="flex flex-column align-items-center unidentified-images">
        <div className={`dismiss-cover${dismissed ? ' activated' : ''}`}></div>
        <div className="flex flex-row">
          {component.front_view_image && (
            <img
              alt="Isometric View"
              title="Isometric View"
              src={
                component.isometric_view_image &&
                component.isometric_view_image.file
              }
            ></img>
          )}
          {component.front_view_image && (
            <img
              alt="Front View"
              title="Front View"
              src={component.front_view_image.file}
            ></img>
          )}
        </div>
        <div className="flex flex-row">
          {component.side_view_image && (
            <img
              alt="Side View"
              title="Side View"
              src={component.side_view_image.file}
            ></img>
          )}
          {component.top_view_image && (
            <img
              alt="Top View"
              title="Top View"
              src={component.top_view_image.file}
            ></img>
          )}
        </div>
      </div>
      <div className="flex flex-row align-items-end unidentified-component-actions">
        <Field
          value={typeSelected}
          label="Component Identification"
          placeholder="Select a component type..."
          select
          isClearable
          options={componentOptions}
          onChange={(typeSelected) => handleSetType(component, typeSelected)}
          disabled={dismissed}
        />
        <div className="action-status">
          {statusLoading && <ScaleLoader color="primary" />}
          {typeSelected && !statusLoading && (
            <i className="bi bi-check-circle"></i>
          )}
        </div>
      </div>
    </div>
  );
};

const UnidentifiedSiteComponents = ({
  components,
  componentOptions,
  componentsLoading,
  getUnidentifiedComponents,
}) => {
  const [identified, setIdentified] = useState([]);
  const [dismissed, setDismissed] = useState([]);

  const identify = async (component, user_tag) => {
    if (!identified.includes(component.id)) {
      setIdentified([component.id, ...identified]);
      const response = await request({
        url: `/sites/${component.site}/components/${component.id}/`,
        method: 'PATCH',
        data: { user_tag: user_tag },
      });
      if (response.status === 204) {
        toast.success('Component identified. 👌');
      }
    }
  };

  const handleDismiss = async (component) => {
    if (!dismissed.includes(component.id)) {
      setDismissed([component.id, ...dismissed]);
      const response = await request({
        url: `/sites/${component.site}/components/${component.id}/`,
        method: 'PATCH',
        data: { deleted: true },
      });
      if (response.status !== 200) {
        setDismissed(dismissed.filter((id) => id !== component.id));
      }
    } else {
      setDismissed(dismissed.filter((id) => id !== component.id));
      const response = await request({
        url: `/sites/${component.site}/components/${component.id}/`,
        method: 'PATCH',
        data: { deleted: false },
      });
      if (response.status !== 200) {
        setDismissed([component.id, ...dismissed]);
      }
    }
  };

  return (
    <div className="unidentified-components">
      <div className="flex flex-row justify-content-space-between align-items-center">
        <h2>Unidentified Components</h2>
        <button
          className={`button-icon${componentsLoading ? ' disabled' : ''}`}
          onClick={() => {
            getUnidentifiedComponents();
            setIdentified([]);
          }}
        >
          <span> Update </span>
          <i className="bi bi-arrow-clockwise"></i>
        </button>
      </div>
      {componentsLoading && <div>Loading...</div>}
      {!componentsLoading && components.length < 1 && (
        <div>No component found.</div>
      )}
      {!componentsLoading && (
        <div className="flex flex-row flex-wrap gap-10">
          {components.map((component, index) => (
            <UnidentifiedComponentCard
              component={component}
              saved={identified.includes(component.id)}
              dismissed={dismissed.includes(component.id)}
              handleDismiss={handleDismiss}
              key={index}
              componentOptions={componentOptions}
              identify={identify}
            />
          ))}
        </div>
      )}
    </div>
  );
};

UnidentifiedSiteComponents.propTypes = {
  components: PropTypes.array,
  componentOptions: PropTypes.array,
  componentsLoading: PropTypes.bool,
  getUnidentifiedComponents: PropTypes.func,
};

UnidentifiedComponentCard.propTypes = {
  component: PropTypes.object,
  identify: PropTypes.func,
  componentOptions: PropTypes.array,
  dismissed: PropTypes.bool,
  handleDismiss: PropTypes.func,
};

export default UnidentifiedSiteComponents;
