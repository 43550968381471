import {
  formatDistanceToNow,
  formatDuration,
  intervalToDuration,
} from 'date-fns';
import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Breadcrumbs from '../../components/Breadcrumbs';
import Table from '../../components/Table';
import { AppContext } from '../../contexts/AppContextProvider';
import locale from 'date-fns/locale/en-GB';
import { ScaleLoader } from '../../components/Spinner/Spinners';
import './JobList.scss';
import { IdtContext } from '../../contexts/IdtContextProvider';

const formatDistanceLocale = {
  xSeconds: '{{count}}s',
  xMinutes: '{{count}}m',
  xHours: '{{count}}h',
  xDays: '{{count}}d',
  xWeeks: '{{count}}w',
  xMonths: '{{count}}m',
  xYears: '{{count}}y',
};

function formatDistance(token, count, options) {
  options = options || {};

  const result = formatDistanceLocale[token].replace('{{count}}', count);

  if (options.addSuffix) {
    if (options.comparison > 0) {
      return 'in ' + result;
    } else {
      return result + ' ago';
    }
  }

  return result;
}

/* eslint-disable react/prop-types */
const jobColumns = () => [
  {
    Header: 'Launch Date',
    accessor: 'created',
    id: 1,

    Cell: ({ row }) => {
      return (
        <div className="flex flex-row align-items-center">
          <i className="bi bi-calendar mr-5"></i>
          <div>
            {formatDistanceToNow(row.original.created_at, {
              addSuffix: true,
            })}
          </div>
        </div>
      );
    },
  },
  {
    Header: 'Site',
    accessor: 'site_name',
    Cell: ({ value, row }) => {
      return (
        <Link
          to={`/site/${row.original.site}/general`}
          className="site-link flex flex-row"
        >
          {value}
          <i className="bi bi-box-arrow-in-up-right"></i>
        </Link>
      );
    },
  },
  {
    Header: 'Job',
    accessor: 'name',
  },

  {
    Header: 'Status',
    accessor: 'last_signal.signal_name',
    Cell: ({ value, row }) => {
      return (
        <div className="flex flex-row mr-5">
          <div className="flex flex-row status-icon mr-5">
            {row.original.last_signal.signal_name === 'complete' && (
              <i className="job-complete bi bi-check-circle-fill"></i>
            )}
            {row.original.last_signal.signal_name === 'error' && (
              <i className="job-error bi bi-x-circle-fill"></i>
            )}
            {row.original.last_signal.signal_name === 'executing' && (
              <ScaleLoader color="primary" />
            )}
          </div>
          {/* For UI reason : Here we uppercase the first Letter and transform the status 'complete' into 'completed' */}
          {value &&
            value[0].toUpperCase() +
              value.slice(1) +
              (value === 'complete' ? 'd' : '')}
        </div>
      );
    },
  },
  {
    Header: 'Duration',
    accessor: 'created',

    Cell: ({ row }) => {
      return (
        <div>
          {(row.original.finished_at && (
            <div className="flex flex-row align-items-center">
              <i className="bi bi-stopwatch-fill mr-5"></i>
              <div>
                {formatDuration(
                  intervalToDuration({
                    start: row.original.finished_at,
                    end: row.original.created_at,
                  }),
                  { locale: { ...locale, formatDistance } }
                )}
              </div>
            </div>
          )) ||
            '-'}
        </div>
      );
    },
  },
  {
    Header: 'Log message',
    accessor: 'last_signal.exception_line',
    Cell: ({ value }) => {
      return value || '-';
    },
  },
];
/* eslint-enable react/prop-types */

const JobList = () => {
  const { jobs } = useContext(AppContext);
  const { enums } = useContext(IdtContext);

  useEffect(() => {}, [jobs]);

  return (
    <div className="job-list">
      <div className="flex flex-row justify-content-center">
        <div className="container">
          <Breadcrumbs separator={<i className="bi-chevron-right" />}>
            <Link to="/">
              <i className="bi-house-door" />
            </Link>
            {<div>Jobs</div>}
          </Breadcrumbs>
          <h1>Last Jobs</h1>
          <Table columns={jobColumns(enums)} data={jobs} />
        </div>
      </div>
    </div>
  );
};

export default JobList;
