import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { Marker } from 'react-leaflet';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ConfirmPopup from '../../../components/ConfirmPopup/ConfirmPopup';
import Item from '../../../components/Item';
import { Map } from '../../../components/Map';
import PointCloudCard from '../../../components/PointCloudCard/PointCloudCard';
import UploadFileModal from '../../../components/UploadFileModal';
import { AppContext } from '../../../contexts/AppContextProvider';
import { request } from '../../../services/api';
import { downloadBlob } from '../../../utilities/helpers';

const GeneralDetails = ({ site, getSite }) => {
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [fileUploadModalOpen, setFileUploadModalOpen] = useState(false);
  const [fileUploadLoading] = useState(false);
  const navigate = useNavigate();
  const { isAdmin } = useContext(AppContext);

  const deleteSite = async (siteId) => {
    let response;
    try {
      response = await request({ url: `/sites/${siteId}/`, method: 'DELETE' });
    } finally {
      setConfirmDeleteOpen(false);
      if (response.status === 204) {
        toast.success('Site correctly deleted. 👌');
        navigate(`/`);
      }
    }
  };

  const uploadFile = (selectedFile) => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append('file', selectedFile, selectedFile.name);
      formData.append('file_type', 'site_point_cloud');
      const config = {
        method: 'PUT',
        data: formData,
        url: `/sites/${site.id}/upload/`,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      toast.promise(
        async () => {
          await request(config);
          getSite(site.id);
        },
        {
          pending: 'Uploading file...',
          success: 'File uploaded, the file is now being processed. 👌',
          error: 'Error during the upload. 🤯',
        }
      );
      setFileUploadModalOpen(false);
    }
  };

  const downloadComponents = async (type) => {
    let fileName;
    let url;
    if (type === 'site') {
      url = `/revit/site/${site.id}`;
      fileName = `site_data_${site.name.replace(/ /g, '_')}.json`;
   }

    const config = {
      method: 'GET',
      url,
      responseType: 'blob',
    };
    const response = await request(config);

    downloadBlob(response.data, fileName);
  };

  return (
    <div className="general-details mb-20">
      <div className="flex grow-1 mb-20">
        <div>
          <Item label={'Name'} value={site.name} dataTestId="site-name" />
          <Item label={'Site type'} value={site.type} dataTestId="site-type" />
          <Item
            label={'Address'}
            value={
              site.land && (
                <>
                  <div>{site.land.address.line_1}</div>
                  <div>{site.land.address.locality}</div>
                  <div>{site.land.address.postal_code}</div>
                </>
              )
            }
            data-testid="site-address"
          />
          <Item
            label="Owner"
            value={site.owned_by && site.owned_by.full_name}
            data-testid="site-owner"
          />
          <div className="actions flex flex-row button-group mb-20 mr-20">
            <button
              onClick={() => navigate(`/site/${site.id}/edit`)}
              className="full-width-button"
              data-testid="edit-site-button"
            >
              <i className="bi bi-pencil"></i>
            </button>
            <button
              onClick={() => setConfirmDeleteOpen(true)}
              className="full-width-button outlined-button"
              data-testid="delete-site-button"
            >
              <i className="bi bi-x-lg"></i>
            </button>
          </div>
        </div>

        {site.position && (
          <Map center={[site.position.x, site.position.y]} zoom={15}>
            <Marker position={[site.position.x, site.position.y]}></Marker>
          </Map>
        )}
      </div>

      {isAdmin && (
        <>
          <div className="flex flex-column">
            <div className="flex flex-row mb-20 align-items-center">
              <PointCloudCard
                label={'Host building point cloud'}
                pointCloud={site.point_cloud}
                className="mb-20 mr-20"
              />
              <div>
                <button
                  onClick={() => {
                    setFileUploadModalOpen(true);
                  }}
                  className="full-width-button"
                >
                  <i className="bi bi-pencil"></i>
                </button>
              </div>
            </div>
                <button
                  onClick={() => {
                    downloadComponents('site');
                  }}
                  className="button"
                >Export for Revit
                </button>
          </div>
        </>
      )}
      <ConfirmPopup
        isOpen={confirmDeleteOpen}
        headerMessage="Are you sure ?"
        bodyMessage={`You will permanently delete the site ${site.name}.`}
        handleConfirm={() => deleteSite(site.id)}
        onRequestClose={() => setConfirmDeleteOpen(false)}
      />
      <UploadFileModal
        isOpen={fileUploadModalOpen}
        onRequestClose={() => {
          setFileUploadModalOpen(false);
        }}
        handleUpload={uploadFile}
        loading={fileUploadLoading}
        secondaryText={'Supported file are .e57, .txt.'}
      />
    </div>
  );
};

GeneralDetails.propTypes = {
  site: PropTypes.object,
  getSite: PropTypes.func,
};

export default GeneralDetails;
