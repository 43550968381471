import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { IdtContext } from '../../contexts/IdtContextProvider';
import UnidentifiedSiteComponents from '../../pages/Site/SiteDetail/UnidentifiedSiteComponents';
import { request } from '../../services/api';

const UnidentifiedSiteComponentsContainer = ({ site }) => {
  const [unidentifiedComponents, setUnidentifiedComponents] = useState([]);
  const [componentsLoading, setComponentsLoading] = useState(false);

  const getUnidentifiedComponents = async () => {
    setComponentsLoading(true);
    const { data: unidentified_components } = await request(
      `/sites/${site.id}/components/?user_tag=UNDEFINED`
    );
    setUnidentifiedComponents(unidentified_components);
    setComponentsLoading(false);
  };

  const { enums } = useContext(IdtContext);

  useEffect(() => {
    getUnidentifiedComponents();
  }, []);

  return (
    <UnidentifiedSiteComponents
      components={
        (unidentifiedComponents && unidentifiedComponents.results) || []
      }
      componentsLoading={componentsLoading}
      componentOptions={enums.technical_component_user_tags}
      getUnidentifiedComponents={getUnidentifiedComponents}
    />
  );
};

UnidentifiedSiteComponentsContainer.propTypes = {
  site: PropTypes.object,
};

export default UnidentifiedSiteComponentsContainer;
