import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import './Breadcrumbs.scss';

const Breadcrumbs = ({ children, separator }) => {
  // Transform children prop into a proper array of child components excluding null values.
  const childrenArray = React.Children.toArray(children);
  return (
    <div className="site-breadcrumbs">
      {childrenArray.map((child, index) => {
        if (child) {
          return (
            <Fragment key={index}>
              {React.cloneElement(child, {
                className: `${
                  (child.props && child.props.className) || ''
                } breadcrumb`,
              })}
              {separator &&
                index !== childrenArray.length - 1 &&
                React.cloneElement(separator, {
                  className: `${
                    (separator.props && separator.props.className) || ''
                  } breadcrumb-separator`,
                })}
            </Fragment>
          );
        }
      })}
    </div>
  );
};

Breadcrumbs.propTypes = {
  children: PropTypes.node,
  separator: PropTypes.object,
};

export default Breadcrumbs;
