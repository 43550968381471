import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ConfirmPopup from '../../../../components/ConfirmPopup/ConfirmPopup';
import Modal from '../../../../components/Modal';
import { Select } from '../../../../components/Select';
import Table from '../../../../components/Table';
import UploadFileModal from '../../../../components/UploadFileModal';
import { request } from '../../../../services/api';
import { downloadBlob } from '../../../../utilities/helpers';
import { format } from 'date-fns';
import ExternalSiteAntennaForm from '../../../SiteComponent/External/SiteAntennaForm/ExternalSiteAntennaForm';

const ExternalSiteAntennas = ({
  site,
  getSiteComponents,
  siteComponents,
  deleteBulkSiteComponents,
  saveAntenna,
}) => {
  const [isComponentFormOpen, setIsComponentFormOpen] = useState(false);
  const [fileUploadModalOpen, setFileUploadModalOpen] = useState(false);
  const [fileUploadType, setFileUploadType] = useState('');
  const [uploadLoading, setUploadLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isBulkDeleteConfirmOpen, setIsBulkDeleteConfirmOpen] = useState(false);
  const navigate = useNavigate();

  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Created',
      accessor: 'created_at',
      Cell: ({ value: createdAt }) => {
        return createdAt && format(new Date(), 'PPpp');
      },
    },
    {
      Header: 'Azimuth',
      accessor: 'azimuth',
      Cell: ({ value: azimuth }) => azimuth && azimuth.toFixed(3) + '°',
    },
    {
      Header: 'Mech. downtilt',
      accessor: 'mechanical_downtilt',
      Cell: ({ value: mechanical_downtilt }) => mechanical_downtilt && mechanical_downtilt.toFixed(3) + '°',
    },
  ];

  const downloadWattwachterJSON = async () => {
    const url = `/wattwachter/site/${site.id}/`;
    const fileName = 'file.wwmobil';

    const config = {
      method: 'GET',
      url,
      responseType: 'blob',
    };
    const response = await request(config);

    downloadBlob(response.data, fileName);
  };

  useEffect(() => {
    getSiteComponents(site.id, { component_type: 'AntennaComponent' });
    return () => getSiteComponents(null);
  }, []);

  const handleUploadComponent = (choice) => {
    switch (choice && choice.value) {
      case 'pointcloud':
        setFileUploadModalOpen(true);
        setFileUploadType('pointcloud');
        break;

      case 'excel':
        setFileUploadModalOpen(true);
        setFileUploadType('excel');
        break;

      case 'manual':
        setIsComponentFormOpen(true);
        break;

      default:
        break;
    }
  };

  const uploadFile = async (selectedFile, params) => {
    if (selectedFile) {
      setUploadLoading(true);
      const formData = new FormData();
      formData.append('file', selectedFile, selectedFile.name);
      let url = '';
      if (fileUploadType === 'pointcloud') {
        formData.append('file_type', params.componentsPointCloudType);
        url = `/sites/${site.id}/upload/`;
      } else {
        url = `/sites/${site.id}/upload_components_excel/`;
      }

      toast.promise(
        async () => {
          await request({
            url,
            method: fileUploadType === 'pointcloud' ? 'PUT' : 'POST',
            data: formData,
            headers: { 'Content-Type': 'multipart/form-data' },
            params,
          });
          getSiteComponents(site.id);
        },
        {
          pending: 'Uploading file...',
          success: 'File uploaded, the file is now being processed. 👌',
          error: 'Error during the upload. 🤯',
        }
      );

      setUploadLoading(false);
      setFileUploadModalOpen(false);
    }
  };

  const handleSaveAntenna = async (data, id) => {
    const response = await saveAntenna(data, id);
    if (response.status === 201) {
      navigate(`/site/${site.id}/antenna/${response.data.id}`);
    }
  };

  const handleExportAntennas = (choice) => {
    switch (choice && choice.value) {
      case 'wattwachter':
        downloadWattwachterJSON();
        break;

      default:
        break;
    }
  };

  return (
    <div className="site-components-details flex flex-column   justify-content-center">
      <div className="flex flex-row justify-content-space-between align-items-center">
        <h2> Antennas </h2>
        <div className="flex row">
          <div className="dropdown-button primary mr-10">
            <Select
              options={[
                { label: 'Manually...', value: 'manual' },
                {
                  label: 'From Wattwachter...',
                  value: 'wattwachter',
                  isDisabled: true,
                },
              ]}
              placeholder="Add antennas..."
              value={null}
              onChange={handleUploadComponent}
              isSearchable={false}
            />
          </div>
          <div className="dropdown-button primary mr-10">
            <Select
              options={[{ label: 'Wattwachter 1.0.3', value: 'wattwachter' }]}
              placeholder="Export antennas..."
              value={null}
              onChange={handleExportAntennas}
              isSearchable={false}
            />
          </div>
        </div>
      </div>
      <div className="filters flex flex-row mb-10 gap-10">
        <button
          className={
            selectedRows.length > 0
              ? 'button full-width-button outlined-button'
              : 'button full-width-button disabled'
          }
          onClick={() => {
            selectedRows.length > 0 && setIsBulkDeleteConfirmOpen(true);
          }}
        >
          <i className="bi bi-trash3" />
        </button>
      </div>
      <Table
        columns={columns}
        data={siteComponents.results || []}
        onRowClick={(component) =>
          navigate(`/site/${site.id}/antenna/${component.id}`)
        }
        loading={siteComponents.loading}
        isRowSelect
        onRowSelect={(rows) => setSelectedRows(rows)}
      />

      <ConfirmPopup
        isOpen={isBulkDeleteConfirmOpen}
        headerMessage="Are you sure ?"
        bodyMessage={`You will permanently delete ${selectedRows.length} components.`}
        handleConfirm={async () => {
          setIsBulkDeleteConfirmOpen(false);
          await deleteBulkSiteComponents(site.id, selectedRows);
          getSiteComponents(site.id);
        }}
        onRequestClose={() => setIsBulkDeleteConfirmOpen(false)}
      />

      <Modal
        isOpen={isComponentFormOpen}
        onRequestClose={() => {
          setIsComponentFormOpen(false);
        }}
      >
        <ExternalSiteAntennaForm
          site={site}
          defaults={{ resourcetype: 'AntennaComponent' }}
          handleSubmit={handleSaveAntenna}
        />
      </Modal>

      <UploadFileModal
        isOpen={fileUploadModalOpen}
        onRequestClose={() => {
          setFileUploadModalOpen(false);
        }}
        handleUpload={uploadFile}
        secondaryText={
          fileUploadType === 'pointcloud'
            ? 'Supported file are .e57.'
            : 'Supported file are .xlsx.'
        }
        loading={uploadLoading}
        pointCloudParams
      />
    </div>
  );
};

ExternalSiteAntennas.propTypes = {
  site: PropTypes.object,
  componentTypes: PropTypes.array,
  installationStatus: PropTypes.array,
  getSiteComponents: PropTypes.func,
  siteComponents: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  deleteBulkSiteComponents: PropTypes.func,
  saveAntenna: PropTypes.func,
};

export default ExternalSiteAntennas;
