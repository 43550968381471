export const downloadBlob = (data, filename) => {
  const downloadUrl = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = downloadUrl;
  link.setAttribute('download', filename); //any other extension
  document.body.appendChild(link);
  link.click();
  link.remove();
};

/**
 * Return the label as string to be displayed from an enum sent by the IDT API.
 * Enum object typically contains a *value* and a *label* property.
 * Return an empty string if no enum matched the value provided in parameters.
 * @param {array} enum The enum to look for a value
 * @param {string} value The matched value
 * @return {string} The label returned by the function as a string. If no label founded, return an empty string.
 */
export const getEnumLabel = (idtEnum, value) => {
  let finalLabel = idtEnum && idtEnum.find((object) => value === object.value);
  return (finalLabel && finalLabel.label) || '';
};

export const getFileUrlWithAuth = (fileUrl) => {
  const container = process.env.REACT_APP_OVH_OBJECT_STORAGE_CONTAINER;
  if (container) {
    const urlSplitted = fileUrl.split('acernis-idt-storage-00');
    return container + (urlSplitted[1] || `/${urlSplitted[0]}`);
  } else {
    return fileUrl;
  }
};
