import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from '../../components/Modal';
import Pagination from '../../components/Pagination';
import Table from '../../components/Table';
import { AntennaModelForm } from '../AntennaModel';
import { ContactForm } from '../Contact';
import './Dashboard.scss';

const Dashboard = ({
  sites,
  sitesLoading,
  sitesPageNumber,
  getSites,
  contacts,
  contactsLoading,
  antennaModels,
  antennaModelsLoading,
  enums,
}) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formType, setFormType] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const sitesColumns = [
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Components',
      accessor: 'components_count',
    },
    {
      Header: 'Locality',
      accessor: 'land',
      Cell: ({ value: land }) => {
        return land && land.address.locality;
      },
    },
    {
      Header: 'Created',
      accessor: 'created_at',
      Cell: ({ value: createdAt }) => {
        return createdAt && format(new Date(createdAt), 'PPpp');
      },
    },
  ];

  const onSiteClick = (site) => {
    navigate(`/site/${site.id}/general`);
  };

  useEffect(() => {
    getSites(1);
  }, []);

  return (
    <div className="site-list">
      <div className="linear-gradient">
        <div className="flex flex-row justify-content-center">
          <div className="container mb-20">
            <div className="flex flex-column">
              <div className="flex flex-row">
                <div className="dashboard-card mr-10">
                  {contactsLoading && <h3>Loading...</h3>}
                  {!contactsLoading && (
                    <div className="flex flex-column">
                      <h3>
                        {contacts &&
                          `${contacts.length || 0} Contact${
                            contacts.length > 1 ? 's' : ''
                          }`}
                      </h3>
                      <div className="flex flex-row justify-content-end">
                        <div className="button-group flex flex-row">
                          <button
                            className="button full-width-button"
                            onClick={() => navigate('contacts')}
                          >
                            <i className="bi-list-ul"></i>
                          </button>
                          <button
                            className="button full-width-button"
                            onClick={() => {
                              setIsModalOpen(true);
                              setFormType('create-contact');
                            }}
                          >
                            <i className="bi-plus-lg"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="dashboard-card">
                  {antennaModelsLoading && <h3>Loading...</h3>}
                  {!antennaModelsLoading && (
                    <div className="flex flex-column">
                      <h3>
                        {antennaModels &&
                          `${antennaModels.length || 0} Antenna model${
                            antennaModels.length > 1 ? 's' : ''
                          }`}
                      </h3>
                      <div className="flex flex-row justify-content-end">
                        <div className="button-group flex flex-row">
                          <button
                            className="button full-width-button"
                            onClick={() => navigate('antenna-models')}
                          >
                            <i className="bi-list-ul"></i>
                          </button>
                          <button
                            className="button full-width-button"
                            onClick={() => {
                              setIsModalOpen(true);
                              setFormType('create-antenna-model');
                            }}
                          >
                            <i className="bi-plus-lg"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex flex-row justify-content-space-between align-items-center">
                <h2> Sites </h2>
                <button
                  className="button-icon"
                  data-testid="new-site-button"
                  onClick={() => navigate('/site/new')}
                >
                  <span> New Site </span>
                  <i className="bi-plus-lg"></i>
                </button>
              </div>
              <Table
                columns={sitesColumns}
                data={sites}
                onRowClick={onSiteClick}
                loading={sitesLoading}
              />
              <div className="flex flex-row justify-content-center">
                <Pagination
                  current={currentPage}
                  onSelectPage={(page) => {
                    setCurrentPage(page);
                    getSites(page);
                  }}
                  pageNumber={sitesPageNumber}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => {
          setIsModalOpen(false);
        }}
      >
        {isModalOpen && formType === 'create-antenna-model' && (
          <AntennaModelForm antennaTypes={enums && enums.antenna_types} />
        )}
        {isModalOpen && formType === 'create-contact' && (
          <ContactForm contactTypes={enums && enums.contact_types} />
        )}
      </Modal>
    </div>
  );
};

Dashboard.propTypes = {
  sites: PropTypes.array,
  sitesLoading: PropTypes.bool,
  sitesPageNumber: PropTypes.number,
  getSites: PropTypes.func,
  contacts: PropTypes.array,
  contactsLoading: PropTypes.bool,
  antennaModels: PropTypes.array,
  antennaModelsLoading: PropTypes.bool,
  enums: PropTypes.object,
};

export default Dashboard;
