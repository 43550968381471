import React from 'react';
import PropTypes from 'prop-types';
import { ScaleLoader } from '../Spinner/Spinners';

const Button = ({ loading, disabled, children, className, dataTestId, onClick }) => {
  return (
    <button
      disabled={disabled}
      className={`${className || ''}${loading ? ' loading' : ''}`}
      onClick={onClick}
      data-testid={dataTestId}
      style={loading ? { color: 'transparent' } : {}}
    >
      {children}
      {loading && (
        <div
          className="loading-icon"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }}
        >
          <div className="flex align-items-center justify-content-center full-height">
            <ScaleLoader />
          </div>
        </div>
      )}
    </button>
  );
};

export default Button;

Button.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
  dataTestId: PropTypes.string,
};
