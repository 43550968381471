import React, { useState } from 'react';
import Table from '../../components/Table';
import PropTypes from 'prop-types';
import Modal from '../../components/Modal';
import ContactForm from './ContactForm';

const ContactList = ({ contacts, contactsLoading, enums }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const modelsColumns = [
    {
      Header: 'Name',
      accessor: 'full_name',
    },
    {
      Header: 'Type',
      accessor: 'contact_type',
      formatter: (contact_type_value) => {
        return (
          enums &&
          enums.contact_types.find(
            (contact_type) => contact_type_value === contact_type.value
          ).label
        );
      },
    },
    {
      Header: 'Phone',
      accessor: 'phone',
    },
    {
      Header: 'Email',
      accessor: 'email',
    },
  ];

  return (
    <div className="flex justify-content-center">
      <div className="container">
        <div className="flex flex-row justify-content-space-between align-items-center">
          <h2> Contacts </h2>
          <button className="button-icon" onClick={() => setIsModalOpen(true)}>
            <span> New Contact </span>
            <i className="bi-plus-lg"></i>
          </button>
        </div>
        <Table
          columns={modelsColumns}
          data={contacts || []}
          loading={contactsLoading}
        />
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => {
          setIsModalOpen(false);
        }}
      >
        <ContactForm
          contactTypes={enums && enums.contact_types}
          handleSubmit={() => setIsModalOpen(false)}
        />
      </Modal>
    </div>
  );
};

ContactList.propTypes = {
  contacts: PropTypes.array,
  contactsLoading: PropTypes.bool,
  enums: PropTypes.object,
};

export default ContactList;
