import React from 'react';
import Modal from '../Modal';
import './ConfirmPopup.scss';
import PropTypes from 'prop-types';

const ConfirmPopup = ({
  cancelMessage = 'Cancel',
  confirmMessage = 'Confirm',
  headerMessage = 'Please confirm',
  bodyMessage,
  handleConfirm,
  isOpen,
  onRequestClose,
}) => {
  return (
    <Modal isOpen={isOpen}>
      <div className="confirm-popup">
        <h2>{headerMessage}</h2>
        {bodyMessage && <p>{bodyMessage}</p>}
        <div className="flex flex-row justify-content-end button-group">
          <button className={'button-link'} onClick={onRequestClose} data-testid="cancel-button">
            {cancelMessage}
          </button>
          <button onClick={handleConfirm} data-testid="confirm-button">{confirmMessage}</button>
        </div>
      </div>
    </Modal>
  );
};

ConfirmPopup.propTypes = {
  bodyMessage: PropTypes.string,
  cancelMessage: PropTypes.string,
  confirmMessage: PropTypes.string,
  headerMessage: PropTypes.string,
  handleConfirm: PropTypes.func,
  isOpen: PropTypes.bool,
  onRequestClose: PropTypes.func,
};

export default ConfirmPopup;
