import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { logout } from './auth';

const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_IDT_API_URL_DEV
    : process.env.REACT_APP_IDT_API_URL_PROD;

/**
 * Overrid axios request method to handle auth, base URL.
 * @param {config} config object options from axios. see : https://axios-http.com/docs/req_config
 * @returns AxiosResponse
 */
export const request = async (config) => {
  let storedUser = localStorage.getItem('user');
  // If this test is not performed, JSON.parse may throw when set to null
  if (storedUser === null || storedUser.length == 0) {
    storedUser = undefined;
  }
  const currentUserAuth = storedUser ? JSON.parse(storedUser) : undefined;

  let finalConfig = {};

  if (typeof config === 'string' || config instanceof String) {
    finalConfig.url = config;
    if (config[0] === '/') {
      finalConfig.baseURL = BASE_URL;
    }
  } else {
    finalConfig = {
      ...config,
    };
    if (config.url[0] === '/') {
      finalConfig.baseURL = BASE_URL;
    }
  }

  if (currentUserAuth && currentUserAuth.token)
    finalConfig.headers = {
      Authorization: `Token ${currentUserAuth.token}`,
      ...config.headers,
    };

  let response;
  try {
    response = await axios.request(finalConfig).then();
  } catch (err) {
    response = err.response;
    if (!config.noErrorNotify) {
      if (err.response && err.response.status === 400) {
        // Here we catch a 400 Bad request error.
        // It means that one or more field are not correctly provided to the endpoint.
        // A React Node type message is created from the API response with the details of the errors.
        const message = (
          <div>
            {Object.keys(err.response.data).map((property, key) => {
              return (
                <div key={key}>
                  {`${property} : ${err.response.data[property]}`}
                </div>
              );
            })}
          </div>
        );
        toast.error(message);
      } else if (err.response && err.response.status === 401) {
        // Here we catch an authentication error and show an appropriated message
        const message = `You are unauthorized to access to that ressource. Please try to log in again.`;
        toast.error(message);
        window.location.replace('/login');
      } else if (err.response && err.response.status > 401) {
        const message = (
          <div>
            <div>{`Status : ${err.response.status}`}</div>
            {err.response.data.detail && (
              <div>{`Details : ${err.response.data.detail}`}</div>
            )}
          </div>
        );
        toast.error(message);
      } else {
        toast.error('An error occured ');
        throw err;
      }
    }
  }

  return response;
};

export const useRequest = (config) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    request(config)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        setError(err);
        // If unauthorized response
        if (err.response && err.response.status === 401) {
          logout();
        }
      })
      .finally(() => setLoading(false));
  }, []);

  return { data, error, loading };
};
