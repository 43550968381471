import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import './Modal.scss';

const CustomModal = ({ children, isOpen, onRequestClose }) => {
  const standardStyle = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '50%',
      maxWidth: '550px',
      maxHeight: '90%',
    },
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={standardStyle}
      appElement={document.getElementById('App')}
    >
      {children}
    </Modal>
  );
};

CustomModal.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool,
  onRequestClose: PropTypes.func,
  className: PropTypes.string,
};

export default CustomModal;
