import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './Pagination.scss';

const Pagination = ({ pageNumber, current, onSelectPage }) => {
  const [leftEllipsis, setLeftEllipsis] = useState(false);
  const [rightEllipsis, setRightEllipsis] = useState(false);
  const [sibling, setSibling] = useState([]);

  useEffect(() => {
    if (pageNumber > 5) {
      if (current === 1) {
        setSibling([current + 1, current + 2]);
      } else if (current === 2) {
        setSibling([current, current + 1]);
      } else if (current > 2 && current < pageNumber - 1) {
        setSibling([current - 1, current, current + 1]);
      } else if (current === pageNumber - 1) {
        setSibling([current - 1, current]);
      } else {
        setSibling([current - 2, current - 1]);
      }

      if (current > 3) {
        setLeftEllipsis(true);
      } else {
        setLeftEllipsis(false);
      }
      if (current < pageNumber - 2) {
        setRightEllipsis(true);
      } else {
        setRightEllipsis(false);
      }
    } else {
      setSibling([...Array(pageNumber).keys()].slice(2));
    }
  }, [current, pageNumber]);

  const handleOnSelectPage = (page) => {
    onSelectPage(page);
  };

  return (
    <>
      {pageNumber > 1 && (
        <div className="pagination">
          <div className="pagination-down"></div>
          <div className="pagination-pages">
            <button
              className={`page-button icon${current === 1 ? ' disabled' : ''}`}
              onClick={() => current > 1 && handleOnSelectPage(current - 1)}
            >
              <i className="bi bi-chevron-left"></i>
            </button>
            <button
              className={`page-button${current === 1 ? ' current' : ''}`}
              onClick={() => handleOnSelectPage(1)}
            >
              {'1'}
            </button>
            {leftEllipsis && <i className="ellipsis bi bi-three-dots"></i>}
            {sibling.map((number) => (
              <button
                key={number}
                className={`page-button${current === number ? ' current' : ''}`}
                onClick={() => handleOnSelectPage(number)}
              >
                {number}
              </button>
            ))}
            {rightEllipsis && <i className="ellipsis bi bi-three-dots"></i>}
            {pageNumber > 1 && (
              <button
                className={`page-button${
                  current === pageNumber ? ' current' : ''
                }`}
                onClick={() => handleOnSelectPage(pageNumber)}
              >
                {pageNumber}
              </button>
            )}
            <button
              className={`page-button icon${
                current === pageNumber ? ' disabled' : ''
              }`}
              onClick={() =>
                current < pageNumber && handleOnSelectPage(current + 1)
              }
            >
              <i className="bi bi-chevron-right"></i>
            </button>
          </div>
          <div className="pagination-up"></div>
        </div>
      )}
    </>
  );
};

Pagination.propTypes = {
  pageNumber: PropTypes.number,
  current: PropTypes.number,
  onSelectPage: PropTypes.func,
};

export default Pagination;
