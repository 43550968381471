import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ConfirmPopup from '../../../components/ConfirmPopup/ConfirmPopup';
import Modal from '../../../components/Modal';
import Pagination from '../../../components/Pagination';
import { Select } from '../../../components/Select';
import Table from '../../../components/Table';
import UploadFileModal from '../../../components/UploadFileModal';
import { request } from '../../../services/api';
import { downloadBlob, getEnumLabel } from '../../../utilities/helpers';
import { SiteComponentCreate } from '../../SiteComponent/SiteComponentForm';

const SiteComponentsDetails = ({
  site,
  componentTypes,
  technicalComponentUserTags,
  installationStatus,
  getSiteComponents,
  siteComponents,
  deleteBulkSiteComponents,
}) => {
  const [isComponentFormOpen, setIsComponentFormOpen] = useState(false);
  const [componentTypeSelected, setComponentTypeSelected] = useState(null);
  const [fileUploadModalOpen, setFileUploadModalOpen] = useState(false);
  const [fileUploadType, setFileUploadType] = useState('');
  const [uploadLoading, setUploadLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isBulkDeleteConfirmOpen, setIsBulkDeleteConfirmOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();

  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ value: status }) => getEnumLabel(installationStatus, status),
    },
    {
      Header: 'Type',
      accessor: 'component_type',
      Cell: ({ value: componentType, row }) =>
        componentType === 'TechnicalComponent'
          ? `Technical : ${getEnumLabel(
              technicalComponentUserTags,
              row.original.user_tag
            )}`
          : getEnumLabel(componentTypes, componentType),
    },
    {
      Header: 'Created',
      accessor: 'created_at',
      Cell: ({ value: createdAt }) => {
        return createdAt && format(new Date(createdAt), 'PPpp');
      },
    },
  ];

  const downloadComponents = async (type) => {
    let fileName;
    let url;
    if (type === 'technical') {
      url = `/sites/${site.id}/download_technical_component_excel/`;
      fileName = `technical_components_list_${site.name.replace(
        / /g,
        '_'
      )}.xlsx`;
    } else if (type === 'antennas') {
      url = `/sites/${site.id}/download_antennas_excel/`;
      fileName = `antennas_list_${site.name.replace(/ /g, '_')}.xlsx`;
    } else if (type === 'buildings') {
      url = `/revit/site/${site.id}/components/`;
      fileName = `buildings_list_${site.name.replace(/ /g, '_')}.json`;
    }

    const config = {
      method: 'GET',
      url,
      responseType: 'blob',
    };
    const response = await request(config);

    downloadBlob(response.data, fileName);
  };

  const handleChangeComponentType = (choice) => {
    const param = {};
    if (choice) {
      if (choice.value === 'is_ignored') {
        param.is_ignored = 'True';
      } else {
        param.component_type = choice.value;
      }
    }
    setComponentTypeSelected(choice);
    getSiteComponents(site.id, param);
  };

  useEffect(() => {
    getSiteComponents(site.id);
    return () => getSiteComponents(null);
  }, []);

  const uploadFile = async (selectedFile, params) => {
    if (selectedFile) {
      setUploadLoading(true);
      const formData = new FormData();
      formData.append('file', selectedFile, selectedFile.name);
      let url = '';
      if (fileUploadType === 'pointcloud') {
        formData.append('file_type', params.componentsPointCloudType);
        url = `/sites/${site.id}/upload/`;
      } else {
        url = `/sites/${site.id}/upload_components_excel/`;
      }

      toast.promise(
        async () => {
          await request({
            url,
            method: fileUploadType === 'pointcloud' ? 'PUT' : 'POST',
            data: formData,
            headers: { 'Content-Type': 'multipart/form-data' },
            params,
          });
          getSiteComponents(site.id);
        },
        {
          pending: 'Uploading file...',
          success: 'File uploaded, the file is now being processed. 👌',
          error: 'Error during the upload. 🤯',
        }
      );

      setUploadLoading(false);
      setFileUploadModalOpen(false);
    }
  };

  return (
    <div className="site-components-details">
      <div className="flex flex-row justify-content-space-between align-items-center">
        <h2> Components </h2>
        <div className="flex row">
          <div>
            <button
              onClick={() => {
                setFileUploadModalOpen(true);
                setFileUploadType('pointcloud');
              }}
              className="button mr-5"
              data-testid="add-components-button"
            >
              Add components
            </button>
          </div>
          <button
            onClick={() => {
              downloadComponents('buildings');
            }}
            className="button"
          >
            Export for Revit
          </button>
        </div>
      </div>
      <div className="filters flex flex-row mb-10 gap-10">
        <button
          className={
            selectedRows.length > 0
              ? 'button full-width-button outlined-button'
              : 'button full-width-button disabled'
          }
          onClick={() => {
            selectedRows.length > 0 && setIsBulkDeleteConfirmOpen(true);
          }}
        >
          <i className="bi bi-trash3" />
        </button>
        <Select
          options={[
            ...componentTypes,
            { value: 'is_ignored', label: 'Ignored Components' },
          ]}
          placeholder="Filter components..."
          value={componentTypeSelected}
          onChange={handleChangeComponentType}
          isClearable
        />
      </div>
      <Table
        columns={columns}
        data={siteComponents.results || []}
        onRowClick={(component) =>
          navigate(`/site/${site.id}/component/${component.id}`)
        }
        loading={siteComponents.loading}
        isRowSelect
        onRowSelect={(rows) => setSelectedRows(rows)}
      />
      <div className="flex fex-row justify-content-center">
        <Pagination
          current={currentPage}
          pageNumber={
            siteComponents && Math.ceil((siteComponents.count || 1) / 20)
          }
          onSelectPage={(page) => {
            setCurrentPage(page);
            getSiteComponents(site.id, { page });
          }}
        />
      </div>

      <ConfirmPopup
        isOpen={isBulkDeleteConfirmOpen}
        headerMessage="Are you sure ?"
        bodyMessage={`You will permanently delete ${selectedRows.length} components.`}
        handleConfirm={async () => {
          setIsBulkDeleteConfirmOpen(false);
          await deleteBulkSiteComponents(site.id, selectedRows);
          getSiteComponents(site.id);
        }}
        onRequestClose={() => setIsBulkDeleteConfirmOpen(false)}
      />

      <Modal
        isOpen={isComponentFormOpen}
        onRequestClose={() => {
          setIsComponentFormOpen(false);
        }}
      >
        <SiteComponentCreate
          site={site}
          defaults={{ resourcetype: 'AntennaComponent' }}
        />
      </Modal>

      <UploadFileModal
        isOpen={fileUploadModalOpen}
        onRequestClose={() => {
          setFileUploadModalOpen(false);
        }}
        handleUpload={uploadFile}
        secondaryText={
          fileUploadType === 'pointcloud'
            ? 'Supported file are .e57.'
            : 'Supported file are .xlsx.'
        }
        loading={uploadLoading}
        pointCloudParams
        fileUploadType={fileUploadType}
      />
    </div>
  );
};

SiteComponentsDetails.propTypes = {
  site: PropTypes.object,
  componentTypes: PropTypes.array,
  technicalComponentUserTags: PropTypes.array,
  installationStatus: PropTypes.array,
  getSiteComponents: PropTypes.func,
  siteComponents: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  deleteBulkSiteComponents: PropTypes.func,
};

export default SiteComponentsDetails;
