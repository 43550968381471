import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { Marker } from 'react-leaflet';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Field, SearchField } from '../../../components/Field';
import { AppContext } from '../../../contexts/AppContextProvider';
import { IdtContext } from '../../../contexts/IdtContextProvider';
import { request } from '../../../services/api';
import { FlyTo, Map } from './../../../components/Map';
import './SiteForm.scss';

/**
 * @param {*} site  If site is provided, it is an update form, otherwise it is a Site creating form.
 * @returns a Site form
 */
const SiteForm = ({ site, contacts, contactsLoading, handleSubmit }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [contactOptions, setContactOptions] = useState([]);
  const [isNewSite] = useState(!site);
  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [ownedBy, setOwnedBy] = useState(null);
  const [addressFilled, setAddressFilled] = useState(false);
  const [line1, setLine1] = useState('');
  const [locality, setLocality] = useState(null);
  const [postalCode, setPostalCode] = useState(null);
  const [country, setCountry] = useState(null);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [position, setPosition] = useState(null);
  const { enums } = useContext(IdtContext);
  const { user } = useContext(AppContext);

  const submitSite = async (event) => {
    event.preventDefault();
    const method = isNewSite ? 'POST' : 'PATCH';
    const uri = isNewSite ? `/sites/` : `/sites/${site.id}/`;

    if (!isLoading) {
      setIsLoading(true);

      const res = await request({
        url: uri,
        method,
        data: {
          url: site && site.url,
          name,
          type: type && type.value,
          workspace: user.workspace_id,
          land: addressFilled
            ? {
                address: {
                  line_1: line1,
                  locality: locality,
                  postal_code: postalCode,
                },
              }
            : null,
          position: addressFilled
            ? {
                x: position[0],
                y: position[1],
                z: 0,
              }
            : null,
          owned_by: (ownedBy && ownedBy.value) || null,
        },
      });
      if (res.status <= 300) {
        if (res.status === 200) {
          toast.success('Site updated ! 👌');
          handleSubmit(res.data);
        } else if (res.status === 201) {
          toast.success('Site created ! 👌');
        }
        navigate(`/site/${res.data.id}/general`);
      }

      setIsLoading(false);
    }
  };

  const searchAddress = async () => {
    if (search) {
      setLoading(true);
      const res = await axios(
        `https://nominatim.openstreetmap.org/search?q=${search}&format=json&addressdetails=1`
      );
      setLoading(false);
      if (res.status === 200 && res.data) {
        const bestMatch = res.data[0];
        if (bestMatch) {
          setPosition([bestMatch.lat, bestMatch.lon]);
          const address = bestMatch.address;
          const road =
            address.road ||
            address.hamlet ||
            address.croft ||
            address.farm ||
            '';
          setLine1(
            `${address.house_number ? `${address.house_number} ${road}` : road}`
          );
          setLocality(
            address.village ||
              address.town ||
              address.city ||
              address.municipality ||
              address.state ||
              address.region
          );
          setPostalCode(address.postcode);
          setCountry(address.country);
          setAddressFilled(true);
        } else {
          toast.info(
            'No address found. Try to add more informations in the search field.'
          );
        }
      }
    }
  };

  useEffect(() => {
    if (contacts) {
      setContactOptions(
        contacts.map((contact) => {
          return { value: contact.id, label: contact.full_name };
        })
      );
    }
  }, [contacts]);

  useEffect(() => {
    if (site && site.owned_by) {
      setOwnedBy(
        contactOptions.find((contact) => contact.value === site.owned_by.id)
      );
    }
  }, [contactOptions]);

  useEffect(() => {
    // If Site provided in props, it means it is an edit form, not a create form.
    if (site) {
      setName(site.name);
      setType(
        enums &&
          enums.site_types.find((site_type) => site_type.value === site.type)
      );
      setLine1(site.land && site.land.address && site.land.address.line_1);
      setLocality(site.land && site.land.address && site.land.address.locality);
      setPostalCode(
        site.land && site.land.address && site.land.address.postal_code
      );
      if (site.land) {
        setAddressFilled(site.land);
      }

      if (site.position) setPosition([site.position.x, site.position.y]);
    }
  }, []);

  const resetAddress = () => {
    setAddressFilled(false);
    setLine1('');
    setLocality('');
    setPostalCode('');
    setCountry('');
    setPosition(null);
  };

  return (
    <div className="new-site-form">
      {!site && (
        <div className="detail-header flex flex-row justify-content-center">
          <div className="container">
            <h1>New site</h1>
          </div>
        </div>
      )}
      <div className="flex justify-content-center">
        <div className="container">
          <div className="flex flex-column">
            <form
              onSubmit={(event) => {
                submitSite(event);
              }}
            >
              <div className="mb-20">
                <h3>Site identification</h3>
                <Field
                  label="Name"
                  dataTestId="site-name-field"
                  placeholder="Site name"
                  value={name}
                  onChange={setName}
                  required
                  className="create-form-field"
                />
                <Field
                  value={type}
                  label="Type"
                  dataTestId="site-type-field"
                  placeholder="Select a site type..."
                  select
                  isClearable
                  options={enums && enums.site_types}
                  className="create-form-field"
                  onChange={setType}
                />
                <Field
                  value={ownedBy}
                  label="Owner"
                  dataTestId="site-owner-field"
                  placeholder="Select a contact..."
                  select
                  isClearable
                  options={contactOptions}
                  defaultOptions={true}
                  cacheOptions
                  className="create-form-field"
                  onChange={setOwnedBy}
                  isLoading={contactsLoading}
                />
              </div>
              <div className="flex flex grow-1">
                <div className="mr-10">
                  <div>
                    <h3>Site Address</h3>
                    <SearchField
                      label="Address"
                      placeholder="Type an address and click search"
                      value={search}
                      dataTestId="site-address-field"
                      onChange={setSearch}
                      className="create-form-field"
                      onSearch={searchAddress}
                      loading={loading}
                    />
                  </div>
                  {addressFilled && (
                    <div className="address-card">
                      {line1 && <div>{line1}</div>}
                      {locality && (
                        <div>{`${locality} ${postalCode || ''}`}</div>
                      )}
                      {country && <div>{country}</div>}
                      <div className="close" onClick={resetAddress}>
                        <i className="bi bi-x-lg"></i>
                      </div>
                    </div>
                  )}
                </div>
                <Map center={[47.2186371, -1.5541362]}>
                  {position && <Marker position={position}></Marker>}
                  <FlyTo position={position} />
                </Map>
              </div>
              <div className="button-group">
                <button
                  type="submit"
                  data-testid={isNewSite ? 'create-site-button' : 'update-site-button'}
                  className={`${isLoading ? 'disabled' : ''}`}
                >
                  {isNewSite ? 'Create Site' : 'Update Site'}
                </button>
                <button
                  type="button"
                  className="button-link"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  {'Cancel'}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

SiteForm.propTypes = {
  site: PropTypes.object,
  contacts: PropTypes.array,
  contactsLoading: PropTypes.bool,
  handleSubmit: PropTypes.func,
};

export default SiteForm;
