import React from 'react';
import PropTypes from 'prop-types';
import './Chip.scss';

const Chip = ({ content, outlined, reversed, className }) => {
  let finalClassName = outlined ? 'chip outlined' : 'chip';
  finalClassName += ` ${reversed && 'reversed'}${className && ' ' + className}`;
  return <div className={finalClassName}>{content}</div>;
};

export default Chip;

Chip.propTypes = {
  content: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  outlined: PropTypes.bool,
  className: PropTypes.string,
  reversed: PropTypes.bool,
};
