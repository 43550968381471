import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from '../../components/Field';

const AntennaBandForm = ({ antennaBand, saveAntennaBand }) => {
  const [electrical_port, setElectricalPort] = useState('');
  const [frequency, setFrequency] = useState('');
  const [power, setPower] = useState('');
  const [attenuation, setAttenuation] = useState('');
  const [electrical_down_tilt, setElectricalDownTilt] = useState('');
  const [is_activated, setIsActivated] = useState(true);

  useEffect(() => {
    if (antennaBand) {
      setFrequency(antennaBand.frequency);
      setElectricalPort(antennaBand.electrical_port);
      setElectricalDownTilt(antennaBand.electrical_down_tilt);
      setPower(antennaBand.power);
      setAttenuation(antennaBand.attenuation || '');
      setIsActivated(antennaBand.is_activated);
    }
    return () => {
      setFrequency('');
      setElectricalPort('');
      setElectricalDownTilt('');
      setPower('');
      setAttenuation('');
      setIsActivated(false);
    };
  }, [antennaBand]);

  const handleSubmit = () => {
    saveAntennaBand(
      {
        frequency,
        electrical_port,
        electrical_down_tilt,
        power,
        attenuation,
        is_activated,
      },
      (antennaBand && antennaBand.id) || null
    );
  };

  return (
    <div>
      <h2>New Antenna Band</h2>
      <Field
        label="Port"
        placeholder="12000"
        value={electrical_port}
        onChange={setElectricalPort}
        required
      />
      <Field
        label="Frequency [MHz]"
        type="number"
        placeholder="350"
        value={frequency}
        onChange={setFrequency}
        required
      />
      <Field
        label="Electrical Downtilt [°]"
        type="number"
        placeholder="3"
        value={electrical_down_tilt}
        onChange={setElectricalDownTilt}
        required
      />
      <Field
        label="Power [W]"
        type="number"
        placeholder="5000"
        value={power}
        onChange={setPower}
        required
      />
      <Field
        label="Attenuation [dB]"
        type="number"
        placeholder="0.5"
        value={attenuation}
        onChange={setAttenuation}
        required
      />
      <Field
        type={'checkbox'}
        label="Active ?"
        value={is_activated}
        onChange={setIsActivated}
      />
      <div className="flex flex-row justify-content-end">
        <button type="submit" className="mb-20" onClick={handleSubmit}>
          Go
        </button>
      </div>
    </div>
  );
};

AntennaBandForm.propTypes = {
  antennaBand: PropTypes.object,
  saveAntennaBand: PropTypes.func,
};

export default AntennaBandForm;
