import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Field } from '../../../components/Field';
import { request } from '../../../services/api';
import { useStateWithDep } from '../../../utilities/hooks';

const SiteComponentForm = ({ siteComponent, enums, siteId, handleSubmit }) => {
  let [name, setName] = useStateWithDep(
    (siteComponent && siteComponent.name) || ''
  );
  const [status, setStatus] = useStateWithDep(
    siteComponent &&
      enums.installation_status.find(
        (status) => status.value === siteComponent.status
      )
  );
  const [technical_component_user_tag, setTechCompUserTag] = useStateWithDep(
    siteComponent &&
      enums.technical_component_user_tags.find(
        (status) => status.value === siteComponent.user_tag
      )
  );
  const navigate = useNavigate();

  const submitComponent = async (event) => {
    event.preventDefault();
    if (siteComponent.component_type === 'TechnicalComponent') {
      const res = await request({
        url: `/sites/${siteId}/components/${siteComponent.id}/`,
        method: 'PATCH',
        data: {
          name,
          status: status.value,
          user_tag: technical_component_user_tag.value,
          component_type: siteComponent.component_type,
          site: siteComponent.site,
        },
      });
      if (res.status === 200) {
        toast.success('Component correctly updated. 👌');
        handleSubmit(res.data);
        navigate(`/site/${siteId}/component/${siteComponent.id}`);
      }
    } else {
      const res = await request({
        url: `/sites/${siteId}/components/${siteComponent.id}/`,
        method: 'PATCH',
        data: {
          name,
          status: status.value,
          component_type: siteComponent.component_type,
          site: siteComponent.site,
        },
      });
      if (res.status === 200) {
        toast.success('Component correctly updated. 👌');
        handleSubmit(res.data);
        navigate(`/site/${siteId}/component/${siteComponent.id}`);
      }
    }
  };

  return (
    <div className="site-component-form flex flex-column">
      <div className="site-component-form-field">
        <form className="mb-20" onSubmit={submitComponent}>
          <Field
            label="Name"
            placeholder="Component Name"
            value={name}
            onChange={setName}
            required
          />
          <Field
            value={status}
            label="Status"
            placeholder="Select a component status..."
            select
            isClearable
            options={enums && enums.installation_status}
            onChange={setStatus}
            className="last-field"
          />
          {siteComponent.component_type === 'TechnicalComponent' && (
            <Field
              value={technical_component_user_tag}
              label="User tag"
              placeholder="Select a technical component user tag..."
              select
              isClearable
              options={enums && enums.technical_component_user_tags}
              onChange={setTechCompUserTag}
              className="last-field"
            />
          )}
          <div className="button-group">
            <button type="submit">Update</button>
            <button
              type="button"
              className="button-link"
              onClick={() => {
                navigate(-1);
              }}
            >
              {'Cancel'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

SiteComponentForm.propTypes = {
  siteComponent: PropTypes.object,
  enums: PropTypes.object,
  siteId: PropTypes.string,
  handleSubmit: PropTypes.func,
};

export default SiteComponentForm;
