import React, { useContext } from 'react';
import { IdtContext } from '../../contexts/IdtContextProvider';
import AntennaModelList from '../../pages/AntennaModel/AntennaModelList';
import { useRequest } from '../../services/api';

const AntennaModelListContainer = () => {
  const { data: antennaModels, loading } = useRequest('/conf/models/antennas/');
  const { enums } = useContext(IdtContext);

  return (
    <AntennaModelList
      antennaModels={antennaModels && antennaModels.results}
      antennaModelsLoading={loading}
      enums={enums}
    />
  );
};

export default AntennaModelListContainer;
