import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Field } from '../../components/Field';
import { request } from '../../services/api';
import './AntennaModel.scss';

const AntennaModelForm = ({ antennaTypes, handleSubmit }) => {
  const navigate = useNavigate();

  const [companyName, setCompanyName] = useState('');
  const [reference, setReference] = useState('');
  const [diameter, setDiameter] = useState('');
  const [height, setHeight] = useState('');
  const [thickness, setThickness] = useState('');
  const [type, setType] = useState(null);

  const submit = async (event) => {
    event.preventDefault();
    const res = await request({
      url: '/conf/models/antennas/',
      method: 'POST',
      data: {
        reference: reference,
        type: type.value,
        manufacturer: { company_name: companyName },
        height,
        thickness,
        diameter,
      },
    });
    if (res.status === 201) {
      navigate('/antenna-models');
      handleSubmit();
    }
  };

  return (
    <div className="antenna-model-form flex flex-column">
      <form onSubmit={submit}>
        <h3>New Antenna Model</h3>
        <Field
          value={type}
          label="Type"
          placeholder="Select a site type..."
          select
          isClearable
          required
          options={antennaTypes}
          onChange={setType}
        />
        <Field
          label="Company Name"
          placeholder="Company Name"
          value={companyName}
          onChange={setCompanyName}
          required
          isClearable
        />
        <Field
          label="Reference"
          placeholder="Antenna Model Reference"
          value={reference}
          onChange={setReference}
          required
        />
        <Field
          label="Diameter"
          type="number"
          placeholder="In meters"
          value={diameter}
          onChange={setDiameter}
          className={'antenna-model-form-field'}
        />
        <Field
          label="Height"
          type="number"
          placeholder="In meters"
          value={height}
          onChange={setHeight}
          className={'antenna-model-form-field'}
        />
        <Field
          label="Thickness"
          type="number"
          placeholder="In meters"
          value={thickness}
          onChange={setThickness}
          className={'antenna-model-form-field'}
        />
        <div className="flex flex-row justify-content-end">
          <button type="submit">Go</button>
        </div>
      </form>
    </div>
  );
};

AntennaModelForm.propTypes = {
  handleSubmit: PropTypes.func,
  antennaTypes: PropTypes.array,
};

export default AntennaModelForm;
