import React from 'react';
import axios from 'axios';

import './PointCloud.scss';
import PropType from 'prop-types';

const PointCloudCard = ({ pointCloud, label, className }) => {
  const finalClassName = className
    ? `${className} point-cloud-card`
    : 'point-cloud-card';

  const downloadFile = (e, pointCloudFile) => {
    /*
     * Helper function that prevents against default html <a> tag behaviour,
     * in order to be able to download all file types
     * (vs opening in browser by default for some file types).
     */
    e.preventDefault();
    axios({
      url: pointCloudFile,
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        pointCloudFile
          .split('https://acernis-bucket-001.s3.amazonaws.com/media/uploads/')
          .join('')
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  return (
    <div className={finalClassName}>
      <div className="label">{label}</div>

      <div className="point-cloud-files">
        {pointCloud && (
          <>
            {pointCloud.pointcloud_type === 'ConcretePointCloud' && (
              <a
                onClick={(e) => downloadFile(e, pointCloud.file.file)}
                target="_blank"
                rel="noopener noreferrer"
                className="point-cloud-file"
                href={pointCloud.file && pointCloud.file.file}
              >
                <div>{'Concrete Point Cloud'}</div>
                <i className="bi bi-download"></i>
              </a>
            )}
            {pointCloud.pointcloud_type === 'ClusterPointCloud' && (
              <>
                <a
                  onClick={(e) =>
                    downloadFile(e, pointCloud.original_point_cloud.file.file)
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  className="point-cloud-file"
                  href={
                    pointCloud.original_point_cloud &&
                    pointCloud.original_point_cloud.file.file
                  }
                >
                  <div>{'Cluster Point Cloud'}</div>
                  <i className="bi bi-download"></i>
                </a>
                <a
                  onClick={(e) => downloadFile(e, pointCloud.indices.file)}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="point-cloud-file"
                  href={pointCloud.indices && pointCloud.indices.file}
                >
                  <div>{'Indices File'}</div>
                  <i className="bi bi-download"></i>
                </a>
              </>
            )}
          </>
        )}
        {!pointCloud && (
          <div className="point-cloud-file empty">
            <i className="bi bi-dash"></i>
          </div>
        )}
      </div>
    </div>
  );
};

PointCloudCard.propTypes = {
  pointCloud: PropType.object,
  label: PropType.string,
  className: PropType.string,
};

export default PointCloudCard;
