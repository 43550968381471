import React from 'react';
import PropTypes from 'prop-types';
import './Cards.scss';

const CoordinatesCard = ({ header, coordinates }) => {
  return (
    <div className="coordinates-card">
      <h3>{header}</h3>
      <div className="coordinate">
        {'x : '}
        {(coordinates && coordinates.x.toFixed(3)) || <i className="bi-dash" />}
      </div>
      <div className="coordinate">
        {'y : '}
        {(coordinates && coordinates.y.toFixed(3)) || <i className="bi-dash" />}
      </div>
      <div className="coordinate">
        {'z : '}
        {(coordinates && coordinates.z.toFixed(3)) || <i className="bi-dash" />}
      </div>
    </div>
  );
};

CoordinatesCard.propTypes = {
  header: PropTypes.string,
  coordinates: PropTypes.object,
};

export { CoordinatesCard };
