import React, { useEffect } from 'react';
import './Table.scss';
import PropTypes from 'prop-types';
import { useRowSelect, useTable } from 'react-table';
import TableCheckbox from './TableCheckbox';

/* eslint-disable react/prop-types */
const useCheckboxColumn = ({ hooks, isRowSelect }) => {
  if (isRowSelect) {
    hooks.visibleColumns.push((columns) => [
      // Let's make a column for selection
      {
        id: 'selection',
        // The header can use the table's getToggleAllRowsSelectedProps method
        // to render a checkbox
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <TableCheckbox {...getToggleAllRowsSelectedProps()} />
        ),
        // The cell can use the individual row's getToggleRowSelectedProps method
        // to the render a checkbox
        Cell: ({ row }) => (
          <TableCheckbox {...row.getToggleRowSelectedProps()} />
        ),
      },
      ...columns,
    ]);
  }
};
/* eslint-enable react/prop-types */

const Table = ({
  data,
  columns,
  onRowClick,
  loading,
  isRowSelect,
  onRowSelect,
}) => {
  const tableInstance = useTable({ data, columns }, useRowSelect, (hooks) =>
    useCheckboxColumn({ hooks, isRowSelect })
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
    selectedFlatRows,
    state: { selectedRowIds },
  } = tableInstance;

  let rowClassName = onRowClick ? 'pointer' : null;

  useEffect(() => {
    if (onRowSelect) {
      // Here we call the onRowSelect on the selectedRowIds state updates and not on the selectedFlatRows value update to avoid
      // infinite loop. selectedFlatRows value is a rendered value which update at every render.
      onRowSelect(selectedFlatRows.map((row) => row.original));
    }
  }, [selectedRowIds]);

  return (
    <div className="idt-table">
      <table {...getTableProps()}>
        <thead>
          {
            // Loop over the header rows
            headerGroups.map((headerGroup, index) => (
              // Apply the header row props
              <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                {
                  // Loop over the headers in each row
                  headerGroup.headers.map((column, index) => (
                    // Apply the header cell props
                    <th
                      key={index}
                      {...column.getHeaderProps({
                        className:
                          column.id === 'selection'
                            ? 'select-header'
                            : column.headerClassName,
                      })}
                    >
                      {
                        // Render the header
                        column.render('Header')
                      }
                    </th>
                  ))
                }
              </tr>
            ))
          }
        </thead>
        {/* Apply the table body props */}
        <tbody {...getTableBodyProps()}>
          {!loading &&
            rows.length > 0 &&
            // Loop over the table rows
            rows.map((row) => {
              // Prepare the row for display
              prepareRow(row);
              return (
                // Apply the row props
                <tr
                  key={row.index}
                  onClick={onRowClick ? () => onRowClick(row.original) : null}
                  {...row.getRowProps()}
                  className={rowClassName}
                >
                  {
                    // Loop over the rows cells
                    row.cells.map((cell, index) => {
                      // Apply the cell props
                      return (
                        <td
                          key={index}
                          {...cell.getCellProps({
                            className:
                              cell.column.id === 'selection'
                                ? 'select-cell'
                                : '',
                          })}
                        >
                          {
                            // Render the cell contents
                            cell.render('Cell')
                          }
                        </td>
                      );
                    })
                  }
                </tr>
              );
            })}
          {rows.length === 0 && !loading && (
            <tr>
              <td
                colSpan={visibleColumns.length}
                style={{ textAlign: 'center' }}
              >
                {'No data retrieved.'}
              </td>
            </tr>
          )}
          {rows.length === 0 && loading && (
            <tr>
              <td
                colSpan={visibleColumns.length}
                style={{ textAlign: 'center' }}
              >
                {'Loading...'}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

Table.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      accessor: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  onRowClick: PropTypes.func,
  loading: PropTypes.bool,
  isRowSelect: PropTypes.bool,
  onRowSelect: PropTypes.func,
};

export default Table;
